import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import SubPageHeader from "../../components/subPageHeader";
import QuoteBox from "../../components/quoteBox";
import ButtonRow from "../../components/buttonRow";
import headingBg from "./images/Collaboration.png";
import tylerJacks from "./images/Dr.Jacks.png";
import testTube from "./images/testtube.png";
import glennBailey from "./images/GlenBailey.logo.png";
import logo from "./images/BTC_LOGO.png";
import logo2 from "./images/stand.jpg";
import logo3 from "./images/wpcc.gif";
import logo4 from "./images/AACR_LOGO.png";
export const Collaboration = () => {
  let sectionMessage = useRef();

  return (
    <main id="main" className="pushtop">
      <SubPageHeader
        background={headingBg}
        firstLine="Led by Lustgarten.
        Powered by Collaboration."
        firstLineColor="white"
      ></SubPageHeader>
      <div className="bordered-padding">
        <section className="section reg-section button-row-wrapper ">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-md-3">
                <div className="logo-wrapper">
                  <img src={logo} alt="logo" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="logo-wrapper">
                  <img src={logo2} alt="logo" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="logo-wrapper">
                  <img src={logo3} alt="logo" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="logo-wrapper">
                  <img src={logo4} alt="logo" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bordered-padding">
        <section className="section subpage-top-sec reg-section basic padding-top bordered-padding">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-md-12 offset-parent">
                <div className="subpage-content">
                  <div className="subpage-content-inner">
                    <div className="content-group">
                      <div className="content-title-2">
                        <h2 className="purple   italicize">
                          Pancreatic Cancer Collective (PCC)
                        </h2>
                      </div>
                      <p className="med-p-2">
                        To hasten the Foundation’s impact on pancreatic cancer
                        research, the Lustgarten Foundation has collaborated
                        with Stand Up To Cancer (SU2C) since 2012. The PCC
                        supports groundbreaking progress in identifying new
                        treatments for pancreatic cancer and improving patient
                        outcomes.
                      </p>
                    </div>
                    <hr />
                    <div className="content-group">
                      <div className="content-title-2">
                        <h2 className="purple ">
                          Results of a Phase I Trial of Adjuvant, Individualized
                          mRNA Neoantigen Vaccine
                        </h2>
                      </div>
                      <p className="med-p-2">
                        At the American Society of Clinical Oncology’s (ASCO)
                        2022 annual meeting, Vinod Balachandran, MD, Memorial
                        Sloan Kettering Cancer Center, presented the results of
                        the first trial testing a personalized messenger RNA
                        (mRNA) vaccine. The two-year, Phase 1 trial was the
                        culmination of years of research leading to the critical
                        discovery that long-term pancreatic cancer survivors
                        have specific immune responses the team hoped to mimic
                        with the vaccines. The ASCO presentation followed the
                        team’s second paper published in the June 9, 2022, issue
                        of the scientific journal{" "}
                        <span className="italicize">Nature</span>.
                      </p>
                      <p className="med-p-2">
                        In this trial, half of the 16 patients treated showed an
                        immune response to the vaccine. Of those eight patients,
                        none showed recurrence for up to 30 months (median
                        follow-up 18 months) and they continue to be followed.
                        In contrast, patients who didn’t respond to the vaccine
                        had a median recurrence-free survival of just over a
                        year. The results suggest the possibility of durable
                        protection against pancreatic cancer if an immune
                        response can be driven against tumor-specific targets.
                        The study also demonstrates the feasibility of
                        developing patient-specific, personalized vaccines in a
                        clinically relevant timeframe.
                      </p>
                      <div className="subpage-detail-2">
                        The trial is funded by the Pancreatic Cancer Collective,
                        a partnership between the Lustgarten Foundation and
                        Stand Up To Cancer to improve pancreatic cancer patient
                        outcomes.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bordered-padding">
        <section className="section  reg-section ">
          <div className="fluid-container">
            <div className="well bg-tan-4">
              <div className="well-inner">
                <div className="row gx-5">
                  <div className="col-md-9">
                    <div className="well-group">
                      <div className="well-title-3">
                        <h2 className="purple">
                          Partnership with Break Through Cancer on “Conquering
                          KRAS in Pancreatic Cancer” Project{" "}
                        </h2>
                      </div>
                      <p className="sm-p">
                        The Lustgarten Foundation is committed to developing a
                        treatment targeting KRAS and has invested $5 million in
                        support of a three-year, $15 million project with Break
                        Through Cancer entitled “Conquering KRAS in Pancreatic
                        Cancer.” Break Through Cancer, led by Tyler Jacks, PhD,
                        Director of the LABS site at MIT, is bringing together
                        teams of researchers from the nation’s top five cancer
                        research centers to collaborate and boldly tackle the
                        biggest challenges in cancer, with a focus on pancreatic
                        cancer, ovarian cancer, and glioblastoma—which all face
                        poor prognoses and slow research progress.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="person purple">
                      <div className="headshot">
                        <img src={tylerJacks} alt="Tyler Jacks" />
                      </div>

                      <div className="name">Tyler Jacks, PhD</div>
                      <div className="title">
                        President, Break Through Cancer
                        <br />
                        Director, LABS site at MIT
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bordered-padding">
        <section className="section  reg-section double-hr">
          <div className="fluid-container">
            <div className="well">
              <div className="well-inner">
                <div className="row gx-5">
                  <div className="col-md-6">
                    <div className="person purple">
                      <div className="photo">
                        <img src={testTube} alt="Tyler Jacks" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="well-group">
                      <p className="med-p-2">
                        Break Through Cancer created a “team lab” structure to
                        maximize interdisciplinary collaboration among
                        researchers and is the firstof-its-kind model for
                        collaboration. Researchers across these five
                        institutions will investigate promising KRAS-targeting
                        therapies to accelerate the translation of new KRAS
                        inhibitors into effective drugs for this disease:
                      </p>
                      <div className="vert-list purple">
                        <ul>
                          <li>Dana-Farber Cancer Institute </li>
                          <li>
                            Sidney Kimmel Comprehensive Cancer Center at Johns
                            Hopkins{" "}
                          </li>
                          <li>Memorial Sloan Kettering Cancer Center </li>
                          <li>
                            Koch Institute for Integrative Cancer Research at
                            MIT{" "}
                          </li>
                          <li>
                            The University of Texas MD Anderson Cancer Center{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bordered-padding">
        <section className="section  reg-section ">
          <div className="fluid-container">
            <div className="well bg-tan-3">
              <div className="well-inner">
                <div className="top-group vert-align">
                  <div className="row gx-5 vert-align">
                    <div className="col-md-9">
                      <div className="well-title-4">
                        <h2 className="grey">
                          Making a Direct and Lasting Impact on Pancreatic
                          Cancer: <br /> Glenn W. Bailey Foundation Provides a
                          Legacy of Hope
                        </h2>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="person purple">
                        <div className="photo">
                          <img
                            src={glennBailey}
                            alt="Glenn W. Bailey Foundation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row gx-5">
                  <div className="col-12">
                    <p className="med-p-3 purple">
                      In 2022, the Glenn W. Bailey Foundation gave a $1.2
                      million gift to support the Robert F. Vizza Lustgarten
                      Foundation’s Clinical Accelerator Initiative (CAI). This
                      gift honors the legacy of Glenn W. Bailey, who passed away
                      from pancreatic cancer in 2007. His late wife, Cornelia T.
                      Bailey, first contributed to the Lustgarten Foundation
                      after his death, allocating donations for general research
                      and later through her Foundation for projects including
                      the Dedicated LABS Program and the PASS-01 clinical trial.
                    </p>
                    <p className="med-p-3 purple">
                      The Glenn W. Bailey Foundation is committed to ensuring
                      the United States remains a global thought leader in all
                      forms of science, technology, engineering, math, and
                      medical research. In support of the Glenn W. Bailey
                      Foundation’s commitment to bringing research from the lab
                      to the clinic even faster, this generous $1.2 million
                      donation is earmarked for the CAI.
                    </p>
                    <p className="med-p-3 purple">
                      “As a foundation, we are committed to providing funding
                      for these important clinical trials. We realized we had
                      the capacity to increase our giving and felt confident
                      this is the perfect time to invest in pancreatic cancer
                      research, so we can help scientists who are already on the
                      cusp of important discoveries make even more progress
                      toward a cure,” said Stephen Vogelsang, Glenn W. Bailey
                      Foundation Board member. “And, with the Lustgarten
                      Foundation’s commitment to donating 100% of every dollar
                      to fund the most groundbreaking research, we feel
                      confident patients will significantly benefit from this
                      gift,” added Eileen Daly, another Glenn W. Bailey
                      Foundation Board member.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="color-border">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </section>
      </div>
      <div className="bordered-padding">
        <section className="section  reg-section basic-padding-top">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-12">
                <div className="well bg-tan-3">
                  <div className="well-inner">
                    <div className="well-intro">
                      <div className="well-group xxl-p light-gray">
                        <div className="med-title-1 small-padding-bottom">
                          <h2 className="purple">
                            Exploring New Avenues for Treatment:
                            Therapeutics-Focused Research Program
                          </h2>
                        </div>

                        <p className="med-p-2">
                          The Therapeutics-Focused Research Program is deepening
                          the scientific and medical community’s understanding
                          of the causes of pancreatic cancer and developing a
                          robust pipeline of potential new treatment options.
                        </p>
                        <p className="med-p-2">
                          This $11.2 million grant program promotes
                          collaboration across labs and is expected to identify
                          new therapeutic approaches and drug targets. Ten
                          projects were selected for funding focusing on key
                          areas of pancreatic cancer biology.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ButtonRow activeSubpage={3} />
    </main>
  );
};
