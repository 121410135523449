import React from "react";
import { Link } from "react-router-dom";
const ButtonRow = (props) => {
  return (
    <section className="section basic-padding reg-section button-row-wrapper">
      <div className="fluid-container">
        <div className="row gx-5">
          <div className="col-md-12">
            <div className="button-row">
              <div>
                <Link
                  to="/research/prevention"
                  className={
                    props.activeSubpage === 1 ? "active-subpage" : "hoverable"
                  }
                >
                  <span>Prevention and Early Detection Initiatives</span>
                </Link>
              </div>

              <div>
                <Link
                  to="/research/CAI"
                  className={
                    props.activeSubpage === 2 ? "active-subpage" : "hoverable"
                  }
                >
                  <span>Clinical Accelerator Initiative</span>
                </Link>
              </div>

              <div>
                <Link
                  to="/research/collaboration"
                  className={
                    props.activeSubpage === 3 ? "active-subpage" : "hoverable"
                  }
                >
                  <span>Powered by Collaboration</span>
                </Link>
              </div>

              <div>
                <Link
                  to="/research/LABS"
                  className={
                    props.activeSubpage === 4 ? "active-subpage" : "hoverable"
                  }
                >
                  <span>LABS Program</span>
                </Link>
              </div>

              <div>
                <Link
                  to="/research/personalized-medicine"
                  className={
                    props.activeSubpage === 5 ? "active-subpage" : "hoverable"
                  }
                >
                  <span>Personalized Medicine</span>
                </Link>
              </div>
              <div>
                <Link
                  to="/research/DEI"
                  className={
                    props.activeSubpage === 6 ? "active-subpage" : "hoverable"
                  }
                >
                  <span>Diversity, Equity & Inclusion</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ButtonRow;
