import React, { useState, useEffect } from "react";

const DownloadButton = (props) => {
  const [error, setError] = useState(null);
  const [blobUrl, setBlobUrl] = useState(null);

  const doFetchDownload = () => {
    fetch("../pdf/report.pdf")
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = "annual-report-2022.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        //alert("your file has downloaded!"); // or you know, something with better UX...
      })
      .catch(() => alert("Download Error"));
  };

  return (
    <>
      <button className="pdf-button" onClick={doFetchDownload}>
        <div className="pdf-icon">
          <img src={props.icon} alt="" />
        </div>
        <span>Download 2022 Report</span>
      </button>
      {error && <p className="error-message">{error}</p>}
    </>
  );
};

export default DownloadButton;
