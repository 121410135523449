import React, { useEffect, useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";

import circleImg1 from "./images/walkpart2.svg";
import circleImg2 from "./images/Family.Community.png";
import circleImg3 from "./images/Community.Girl.png";
import circleImg4 from "./images/2.8.svg";
import circleImg5 from "./images/byyou2.svg";

function CommunityContent() {
  let sectionCommunity = useRef(null);
  let secAnimContainer = useRef(null);

  const [isSecAnimActive, setIsSecAnimActive] = React.useState(false);

  useEffect(() => {
    let heroCircle1 = document.querySelector(".page-circle-1");
    let observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsSecAnimActive(true);
          observer.unobserve(heroCircle1);
        }
      });
    });
    observer.observe(heroCircle1);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div>
      <section
        id="sectionCommunity"
        className="section basic-section animated-section basic-padding bg-tan"
      >
        <div
          ref={secAnimContainer}
          className={`sec-animation-container${
            isSecAnimActive ? " section-anim-active" : ""
          }`}
        >
          <div className="circle-canvas-cont">
            <div className="row">
              <div className="col-6">
                <div className="circle-canvas">
                  <div className="cc4-animated hcanim">
                    <div className="page-circle-4">
                      <img
                        src={circleImg1}
                        alt="250 million invested in research since inception"
                      />
                    </div>
                  </div>
                  <div className="cc5-animated hcanim desktop-only">
                    <div className="page-circle-5">
                      <img
                        src={circleImg2}
                        alt="250 million invested in research since inception"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="circle-canvas">
                  <div className="cc1-animated hcanim">
                    <div className="page-circle-1">
                      <img
                        src={circleImg3}
                        alt="250 million invested in research since inception"
                      />
                    </div>
                  </div>
                  <div className="cc2-animated hcanim desktop-only">
                    <div className="page-circle-2">
                      <img
                        src={circleImg4}
                        alt="318 Research projects across 81 academic institutions"
                      />
                    </div>
                  </div>
                  <div className="cc3-animated hcanim desktop-only">
                    <div className="page-circle-3">
                      <img
                        src={circleImg5}
                        alt="100 percent of all donations fund pancreatic cancer research"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="link-wrapper"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CommunityContent;
