import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import SubPageHeader from "../../components/subPageHeader";
import ButtonRow from "../../components/buttonRow";
import headingBg from "./images/Research.png";
import asideBg from "./images/BlueCancer.png";
import klein from "./images/Alison-Klein-copy.png";
import wood from "./images/LDWood.png";
export const Detection = () => {
  return (
    <main id="main" className="pushtop">
      <div className="bordered-padding">
        <section className="section reg-section med-padding-top med-padding-bottom headshot-title-sec">
          <div className="fluid-container">
            <div className="simple-header">
              <img
                src={headingBg}
                alt="a researcher looking through a microscope"
              />
            </div>
            <div className="row gx-5">
              <div className="col-lg-8 offset-parent">
                <div className="title">
                  <div className="title-inner">
                    <h1 className="purple">Early Detection and Interception</h1>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 offset-parent">
                <div className="head-row">
                  <div className="head-row-inner">
                    <div className="headshot-wrapper headshot-1">
                      <div className="headshot">
                        <img src={klein} alt="Dr. Alison Klein" />
                      </div>
                      <div className="name  purple">
                        Dr.&nbsp;Alison&nbsp;Klein
                      </div>
                    </div>
                    <div className="headshot-wrapper headshot-1">
                      <div className="headshot">
                        <img src={wood} alt="Dr. Laura D. Wood" />
                      </div>
                      <div className="name  purple">
                        Dr.&nbsp;Laura D.&nbsp;Wood
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <hr />
      <div className="bordered-padding">
        <section className="section reg-section med-padding-top">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-lg-12 offset-parent">
                <div className="subpage-content">
                  <div className="subpage-content-inner">
                    <div className="col-content col-three para-group">
                      <h2 className="subtle-heading">
                        Research Projects with Dr.&nbsp;Alison&nbsp;Klein and
                        Dr.&nbsp;Laura&nbsp;D.&nbsp;Wood
                      </h2>

                      <p className="med-p">
                        Alison&nbsp;Klein,&nbsp;PhD, Professor of Oncology,
                        Johns Hopkins School of Medicine, is using updated ways
                        of sequencing genes (including long-read sequencing, a
                        DNA sequencing technique to identify long sequences of
                        DNA, and machine learning) to determine if there are
                        other genes besides the ones already known to increase
                        the risk for pancreatic cancer in families. This is
                        critical because in approximately 80% of families with
                        inherited pancreatic cancer, the gene causing the risk
                        is unknown. Prior Lustgarten-funded research conducted
                        by the Pancreatic Cancer Genetic Epidemiology
                        Consortium, co-led by Dr. Alison Klein and Dr. Gloria
                        Petersen, resulted in the discovery of pancreatic cancer
                        risk genes, including ATM and PALB2.
                      </p>
                      <p className="med-p">
                        Laura D. Wood, MD, PhD, Associate Professor, Johns
                        Hopkins University, developed CODA, a three-dimensional
                        modeling technique using slices of tissues to build a
                        model showing what happens when cells touch other cells
                        to determine if that interaction has an impact on
                        pancreatic cancer development. She is looking at the
                        cellular and molecular alterations associated with
                        increased risk in patients with older age and familial
                        pancreatic cancer. Using CODA, she will analyze normal
                        pancreatic tissue and examine the specific cellular and
                        molecular changes that occur in older patients who are
                        at an increased familial risk. Dr. Wood is also
                        examining people with precancerous lesions called PanINs
                        to understand what causes a lesion to change from
                        benign, which requires monitoring, to malignant, which
                        requires surgical intervention.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bordered-padding">
        <section className="section  reg-section basic-padding-top">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-md-8">
                <aside className="well bg-tan-4">
                  <div className="well-inner">
                    <div className="well-intro">
                      <div className="well-group">
                        <div className="thick-title-1">
                          <h2 className="light-gray  italicize title-padding">
                            Dr. Klein and her team aim to:{" "}
                          </h2>
                        </div>

                        <ul className="list-style-2 teal">
                          <li>
                            Identify genetic causes of familial pancreatic
                            cancer
                          </li>
                          <li>
                            Discover new candidate pancreatic cancer genes that
                            were not found using short-read sequencing
                          </li>
                          <li>
                            Identify families with genetic variants in known
                            pancreatic cancer genes that were missed using
                            conventional sequencing approaches
                          </li>
                          <li>
                            Create new algorithms to identify the cause of
                            inherited diseases including cancers
                          </li>
                          <li>
                            Develop effective methods for large-scale,
                            long-range genomic sequencing so doctors can monitor
                            for risk in their patients
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
              <div className="col-md-4">
                <div
                  className="aside-image"
                  style={{ backgroundImage: `url(${asideBg})` }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
