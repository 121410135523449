import React, { useRef } from "react";
import { Link } from "react-router-dom";
function SectionMission() {
  let sectionMessage = useRef();
  let sectionHeader = useRef(null);
  let sectionHeaderImg = useRef(null);

  return (
    <section
      id="Mission"
      className="section basic-section animated-section basic-padding"
      ref={sectionMessage}
    >
      <div className="fluid-container">
        <div className="row gx-5">
          <div className="col-md-10 offset-md-1">
            <header ref={sectionHeader} className="section-title">
              <div className="section-title-inner">
                <h2 className="big-title tan-1 text-center">
                  <div className="single-line">
                    Mission Focused<span className="period purple">.</span>
                  </div>
                </h2>
              </div>
            </header>
            <div className="mission-intro basic-p mtop-2">
              The Lustgarten Foundation is the largest private funder of
              pancreatic cancer research in the world, funding preeminent
              pancreatic cancer researchers, driving the pursuit of bold and
              innovative science toward earlier detection and better treatments
              and transforming pancreatic cancer into a curable disease. The
              Foundation funds research where creative risks yield high rewards
              to accelerate and expand life-saving treatment options. We believe
              time is everything to patients and their families, and that
              community is power. Lustgarten programs and events provide people
              affected by pancreatic cancer a voice and a place to create hope,
              together.
            </div>
            <div className="mission-cta quote-p light-gray text-center mtop-2 mx-auto max-sm">
              100% of all donations fuel the research to advance understanding
              of this complex, devastating and historically underfunded cancer.
            </div>
            <div className="link-wrapper">
              <a
                target="_blank"
                rel="noreferrer"
                className="mx-auto sec-link link-style-1"
                href="https://lustgarten.org/donatenow/"
              >
                Donate
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionMission;
