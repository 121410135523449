import "./fonts/Lato-Regular.ttf";
import "./fonts/Lato-Italic.ttf";
import "./fonts/Lato-Light.ttf";
import "./fonts/Lato-LightItalic.ttf";
import "./style.css";
import "./App.scss";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet,
  useLocation
} from "react-router-dom";
import { Home } from "./pages/home";
import React, { useEffect, useState } from "react";
import { Donors } from "./pages/donors";
import { Research } from "./pages/research";
import { Community } from "./pages/community";
import { Letter } from "./pages/letter";
import { Leadership } from "./pages/leadership";
import { Financials } from "./pages/financials";
import { Prevention } from "./pages/prevention";
import { Collaboration } from "./pages/collaboration";
import { Detection } from "./pages/detection";
import { Medicine } from "./pages/medicine";
import { LABS } from "./pages/labs";
import { CAI } from "./pages/cai";
import { DEI } from "./pages/dei";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" key="home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/donors" element={<Donors />} />
          <Route path="/letter" element={<Letter />} />
          <Route path="/community" element={<Community />} />
          <Route path="/leadership" element={<Leadership />} />
          <Route path="/financials" element={<Financials />} />
          <Route path="/research" element={<Research />} />
          <Route path="/research/LABS" element={<LABS />} />
          <Route path="/research/CAI" element={<CAI />} />
          <Route path="/research/DEI" element={<DEI />} />
          <Route path="/research/prevention" element={<Prevention />} />
          <Route path="/research/detection" element={<Detection />} />
          <Route path="/research/collaboration" element={<Collaboration />} />
          <Route
            path="/research/personalized-medicine"
            element={<Medicine />}
          />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function Layout() {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log("useEffect triggered for ", pathname);
    setTimeout(() => {
      window.scrollTo({
        top: 0,

        behavior: "instant"
      });
    }, 0);
  }, [pathname]);

  return (
    <div>
      <Header />

      <Outlet />
      <Footer />
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
