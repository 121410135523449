import React from "react";
import { Link } from "react-router-dom";
import { Socialicons } from "../components/icons";
import footerLogo from "../images/footer-logo.png";
import footerImg1 from "../images/charity.png";
import footerImg2 from "../images/GuideStar.png";
import footerImg3 from "../images/BBB.png";
import outLink from "../images/outLink.png";
const Footer = () => {
  return (
    <>
      <footer className="footer basic-padding">
        <div className="fluid-container">
          <div className="row">
            <div className="col-sm-8">
              <Socialicons />
              <div className="footer-social">
                <ul>
                  <li>
                    <a
                      href="https://lustgarten.org/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img className="out-link" src={outLink} alt="" />
                      <span className="social-handle">lustgarten.org</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="row">
                <div className="col-12">
                  <div className="footer-logo-wrapper">
                    <Link to="/">
                      <img
                        className="footer-logo"
                        alt="Lustgarten Pancreatic Research"
                        src={footerLogo}
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row mtop-2">
                <div className="col-6">
                  <img
                    src={footerImg1}
                    className="footer-img"
                    alt="Charity Navigator - Four Star Charity"
                  />
                </div>
                <div className="col-6">
                  <img
                    src={footerImg2}
                    className="footer-img"
                    alt="GuideStar Platinum Participant"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
