import React from "react";

const PageHeader = (props) => {
  return (
    <header
      className="img-heading page-title basic-heading"
      style={{
        backgroundImage: `url(${props.background})`,
        backgroundSize: "cover"
      }}
    >
      <div
        className={`fluid-container ${
          props.offsetSecondLine ? "" : "not-offset2nd"
        }`}
      >
        <div className="page-title-inner">
          <h2 className="big-title offset-b-title">
            <div className={`first-line ${props.firstLineColor}`}>
              {props.firstLine}
            </div>
            <div className={`second-line ${props.secondLineColor}`}>
              {props.secondLine}
              <span className={`period ${props.punctuationColor}`}>
                {props.punctuation}
              </span>
            </div>
          </h2>
        </div>
      </div>
    </header>
  );
};
PageHeader.defaultProps = {
  firstLine: "progress is",
  firstLineColor: "tan-1",
  secondLine: "paramount:",
  secondLineColor: "tan-1",
  punctuation: ".",
  punctuationColor: "tan-1",
  offsetSecondLine: true
};
export default PageHeader;
