import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import menuImg from "../images/menu-img.png";
import menuRightBg from "../images/menu-right-bg.png";
import pdfIcon from "../images/pdf-icon.png";
import DownloadButton from "../components/downloadButton";
import {
  menuLinksShow,
  menuLeftShow,
  menuRightShow,
  showInfo,
  menuHide,
  navHide,
  menuInfoHide,
  staggerLinks,
  hoverLink,
  hoverExit
} from "./Animate";

const Menu = ({ state, removeToggleActive, menuOpen, handleTabPress }) => {
  //create refs for our DOM elements

  let menuWrapper = useRef(null);
  let show1 = useRef(null);
  let show2 = useRef(null);
  let show3 = useRef(null);
  let info = useRef(null);
  let line1 = useRef(null);
  let line2 = useRef(null);
  let line3 = useRef(null);
  let line4 = useRef(null);
  let line5 = useRef(null);
  let line6 = useRef(null);
  let line7 = useRef(null);

  let donateMain = useRef(null);
  useEffect(() => {
    if (!menuOpen) {
      navHide(show3);
      //menuInfoHide(info);
      menuHide(show2, show1);
      gsap.to(menuWrapper, { duration: 1, css: { display: "none" } });
    } else {
      gsap.to(menuWrapper, { duration: 0, css: { display: "block" } });
      gsap.to([show1, show2, show3], {
        duration: 0,
        opacity: 1,
        height: "100%"
      });
      gsap.to([info], {
        duration: 0,
        opacity: 1
      });
      //menuLinksShow(show3);
      menuLeftShow(show1);
      menuRightShow(show2);
      showInfo(info);
      staggerLinks(line1, line2, line3, line4, line5, line6, line7);
      setTimeout(() => {
        line1.focus(); // set focus on first link in menu
      }, 550);
    }
  }, [menuOpen]);

  return (
    <div
      id="hamburger-menu"
      ref={(el) => (menuWrapper = el)}
      className="hamburger-menu"
    >
      <div ref={(el) => (show2 = el)} className="menu-left-background">
        <div ref={(el) => (show3 = el)} className="menu-layer">
          <div className="menu-container">
            <div className="main-menu-wrapper">
              <div className="menu-links">
                <nav>
                  <div className="mwrapper">
                    <ul>
                      <li>
                        <Link
                          onClick={removeToggleActive}
                          onMouseEnter={(e) => hoverLink(e)}
                          onMouseOut={(e) => hoverExit(e)}
                          ref={(el) => (line1 = el)}
                          to="/"
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={removeToggleActive}
                          onMouseEnter={(e) => hoverLink(e)}
                          onMouseOut={(e) => hoverExit(e)}
                          ref={(el) => (line2 = el)}
                          to="/letter"
                        >
                          Dear Friends
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={removeToggleActive}
                          onMouseEnter={(e) => hoverLink(e)}
                          onMouseOut={(e) => hoverExit(e)}
                          ref={(el) => (line3 = el)}
                          to="/research"
                        >
                          Research
                        </Link>
                      </li>

                      <li>
                        <Link
                          onClick={removeToggleActive}
                          onMouseEnter={(e) => hoverLink(e)}
                          onMouseOut={(e) => hoverExit(e)}
                          ref={(el) => (line4 = el)}
                          to="/community"
                        >
                          Community
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={removeToggleActive}
                          onMouseEnter={(e) => hoverLink(e)}
                          onMouseOut={(e) => hoverExit(e)}
                          ref={(el) => (line5 = el)}
                          to="/financials"
                        >
                          Financials
                        </Link>
                      </li>

                      <li>
                        <Link
                          onClick={removeToggleActive}
                          onMouseEnter={(e) => hoverLink(e)}
                          onMouseOut={(e) => hoverExit(e)}
                          ref={(el) => (line6 = el)}
                          to="/donors"
                        >
                          Donors
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={removeToggleActive}
                          onMouseEnter={(e) => hoverLink(e)}
                          onMouseOut={(e) => hoverExit(e)}
                          ref={(el) => (line7 = el)}
                          to="/leadership"
                        >
                          Leadership
                        </Link>
                      </li>
                      <li className="special-donate-link">
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="https://lustgarten.org/donatenowhttps://lustgarten.org/donatenow?site=annualreport"
                        >
                          <span>Donate</span>
                        </a>
                      </li>
                      <li>
                        <DownloadButton
                          icon={pdfIcon}
                          dlUrl="../pdf/lustgarten-annual-report-2022.pdf"
                        />
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={(el) => (show1 = el)}
        className="menu-right-background"
        style={{ backgroundImage: `url(${menuRightBg})` }}
      >
        <div ref={(el) => (info = el)} className="menu-info">
          <div className="mwrapper">
            <div className="mwrapper-inner">
              <a
                rel="noreferrer"
                target="_blank"
                className="main-donate"
                ref={(el) => (donateMain = el)}
                href="https://lustgarten.org/donatenow/"
                onKeyDown={(e) => handleTabPress(e)}
              >
                Donate
              </a>
              <div className="donate-desc">
                <p>
                  100% of all donations fuel the research to advance
                  understanding of this complex, devastating, and historically
                  underfunded cancer.
                </p>
              </div>
              <div className="menu-img">
                <img
                  src={menuImg}
                  alt="Scientist looking through microscope lens."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
