import React from "react";

const LetterContent = () => {
  return (
    <div>
      {" "}
      <p>
        Guided by our mission to transform pancreatic cancer into a curable
        disease, Lustgarten is accelerating progress to provide a more hopeful
        future for all patients. Lustgarten’s collaborative, science-focused
        strategy drives outcomes across the Foundation’s three research
        pillars—early detection, new drug development, and personalized
        medicine—with a concentration on translating our understanding of the
        underlying biology of pancreatic cancer into clinical application for
        patients.
      </p>
      <p>
        While 2022 continued to be uniquely challenging, we are grateful to the
        pancreatic cancer research community for implementing bold approaches to
        save lives. And you, the Lustgarten community, are as essential to
        advancing research as every investigator we fund. Critical breakthroughs
        would not be possible without Lustgarten’s dedicated community of
        donors, volunteers, fundraisers, and corporate partners. Your
        persistence and energy inspire us daily; your continued support of our
        mission allows us to wield the power of research to extend lives.
      </p>
      <p>
        Led by Lustgarten and powered by you, we are building momentum for the
        decade ahead—and beyond—to help pancreatic cancer patients not just
        survive but thrive. We are developing innovative, new grant programs,
        expanding collaborations, establishing a network of LABS sites,
        broadening our early detection research, and training the next
        generation of scientists to ensure the continuity and advancement of
        progress for patients.
      </p>
      <p>
        While the future is here with startling progress in cancer vaccine
        development, funded by Lustgarten, the trends in pancreatic cancer are
        deeply worrisome. Although pancreatic cancer accounts for only 3% of new
        cancer diagnoses in the United States, it accounts for 8% of cancer
        deaths. Research predicts pancreatic cancer will become the second
        deadliest cancer in the United States by 2030, second only to lung
        cancer. From 2001 to 2018, incidence rates of pancreatic cancer
        increased by 1% per year among both men and women; from 2001 to 2019,
        death rates increased by 0.2% per year for both sexes. Any increase in
        incidence and decrease in survival rates is concerning and reflects both
        the difficulty of diagnosing pancreatic cancer early and the
        complexities of treating it. Data like these reinforce the urgent need
        for research. Thanks to you, we are positioned to meet this challenge
        and drive progress.
      </p>
      <p>
        Led by the vision of our founders and highly motivated Board of
        Directors, we—and our deep bench of investigators—will continue to forge
        ahead to change disease outcomes benefiting the pancreatic cancer
        community. Your unwavering support since our founding in 1998 has
        allowed us to invest more than $250 million in high-risk, high-reward
        science while being mindful that equity, diversity, and inclusion across
        the research we fund and the scientists we support strengthen outcomes.
        Community is power, and we are stronger because of you.
      </p>
      <p>
        Thanks to you, the Lustgarten Foundation is the backbone and anchor of
        the pancreatic cancer community. You are our inspiration and the reason
        we will keep pushing forward. As we commemorate our 25th anniversary
        this year, we are rededicating ourselves to what matters most: pursuing
        life-saving research. Together we will make 2023 a landmark year of
        discoveries when people with pancreatic cancer have more treatment
        options, better outcomes, and more time with loved ones.
      </p>
    </div>
  );
};

export default LetterContent;
