import React from "react";

const SectionHeader = (props) => {
  return (
    <header className="section-title">
      <div className="section-title-inner">
        <h2
          className={`big-title offset-title  ${
            props.doubleOffset === "true" ? "double-offset" : ""
          } ${props.offsetTop === "true" ? "offset-top" : ""}`}
        >
          <div className={`first-line ${props.firstLineColor}`}>
            {props.firstLine}
          </div>
          <div className={`second-line ${props.secondLineColor}`}>
            {props.secondLine}
            <span className={`period ${props.punctuationColor}`}>
              {props.punctuation}
            </span>
          </div>
        </h2>
      </div>
    </header>
  );
};
SectionHeader.defaultProps = {
  firstLine: "progress is",
  firstLineColor: "tan-1",
  secondLine: "paramount",
  secondLineColor: "tan-1",
  punctuation: ".",
  punctuationColor: "tan-1"
};
export default SectionHeader;
