import React, { useEffect, useLayoutEffect, useRef } from "react";
import "./style.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useDispatch } from "react-redux";

import bgImg1 from "./images/PinkCancer.jpg";
import wormholeImg from "./images/PurpleCancer.Desktop.jpg";
import wormholeMobileImg from "./images/PurpleCancer.Mob.jpg";
import researcher from "./images/Scientist-600px.png";
import circleImg1 from "./images/250.c.svg";
import circleImg2 from "./images/318.c.2.svg";
import circleImg3 from "./images/100.c.svg";

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals("ScrollTrigger", ScrollTrigger);

//ScrollTrigger.normalizeScroll(true); // enable
ScrollTrigger.config({
  // limitCallbacks: true,
  // fixes safari overcroll issues
  ignoreMobileResize: true
});
function Hero() {
  const dispatch = useDispatch();
  const updateAnimActive = (isFirstAnimActive) => {
    return {
      type: "UPDATE_ANIM_ACTIVE",
      payload: isFirstAnimActive
    };
  };
  let homeAnimation = useRef();
  let animHeaderPurp = useRef(null);
  let animHeaderTan = useRef(null);
  let animContent = useRef(null);
  let background1 = useRef(null);
  let wormholePage = useRef(null);
  let wormholeRing = useRef(null);
  let followerInner = useRef(null);
  let annualOne = useRef(null);

  let hero = useRef(null);
  let centeringContainer = useRef(null);

  const [isFirstAnimActive, setIsFirstAnimActive] = React.useState(false);
  const [isSecondAnimActive, setIsSecondAnimActive] = React.useState(false);
  const [dimensions, setDimensions] = React.useState({
    height: document.documentElement.clientHeight,
    width: window.innerWidth,
    animContentHeight: animContent.offsetHeight,
    animContentTop: animContent.offsetTop
  });

  const [isMobile, setIsMobile] = React.useState(false);
  // wormhole background image styles
  const desktopStyle = {
    backgroundImage: `url(${wormholeImg})`
  };

  const mobileStyle = {
    backgroundImage: `url(${wormholeMobileImg})`
  };
  useEffect(() => {
    function handleResize(animContent) {
      setDimensions({
        height: document.documentElement.clientHeight,
        width: window.innerWidth,
        animContentHeight: animContent.offsetHeight,
        animContentTop: animContent.offsetTop
      });
      setIsMobile(window.innerWidth <= 992);
      console.log("resize");
    }
    document.fonts.ready.then((animContent) => {
      // since the layout depends on the font theres a need to reset dimensions when fonts load fully
      // handleResize(animContent);
    });
    window.addEventListener("resize", handleResize, animContent);

    return (_) => {
      window.removeEventListener("resize", handleResize, animContent);
    };
  }, [animContent]);

  useLayoutEffect(() => {
    //console.log("dimH1: " + dimensions.animContentHeight);
    // size/position computations
    setDimensions({
      height: document.documentElement.clientHeight,
      width: window.innerWidth,
      animContentHeight: animContent.offsetHeight,
      animContentTop: animContent.offsetTop
    });
    setIsMobile(window.innerWidth <= 992);

    //console.log("aniC" + animContent.offsetHeight);
    //console.log("aniCT" + animContent.offsetTop);
    //console.log("lead" + leading.offsetHeight);
    //console.log("sublead" + subLeading.offsetHeight);
    let animContentEnd =
      dimensions.animContentHeight + dimensions.animContentTop + 100;

    let maxDimen = Math.max(window.innerWidth, animContentEnd);
    let gutter = (window.innerWidth - 768) * 0.5 - 50;
    //console.log("dimH3: " + animContent.animContentHeight);
    //console.log("dimH2: " + dimensions.animContentHeight);
    // set CSS vars
    let root = document.documentElement;
    root.style.setProperty("--animHeight", animContentEnd + "px");
    root.style.setProperty("--maxDimen", maxDimen + "px");
    root.style.setProperty("--windowHeight", dimensions.height + "px");
    //console.log("dimHeight: " + dimensions.height);
    //console.log("dimanimHeight: " + dimensions.animContentHeight);
    // set "follower/scientist" size based on page gutter
    const h1ImageHeight = (window.innerWidth * 0.6) / 4.45;
    const followerVertMax = window.innerHeight - h1ImageHeight - 260;
    //const followerSize = follower.getBoundingClientRect().width;
    const followSizeDesktop = Math.min(followerVertMax, gutter - 30);
    const followSizeMobile = Math.min(
      followerVertMax,
      window.innerWidth * 0.33 - 50
    );
    if (window.innerWidth > 1400) {
      root.style.setProperty("--followerMaxSize", followSizeDesktop + "px");
    } else {
      root.style.setProperty("--followerMaxSize", followSizeMobile + "px");
    }

    //console.log("wh- " + dimensions.height);
  }, [dimensions.animContentHeight, dimensions.animContentTop, animContent]);

  useLayoutEffect(() => {
    const prefersReducedMotion = window.matchMedia(
      "(prefers-reduced-motion: reduce)"
    ).matches;

    if (!prefersReducedMotion) {
      // run gsap animations if 'reduce motion' is  not set

      let ctx = gsap.context(() => {
        const mainAnimDistanceW =
          window.innerWidth < 768
            ? 10
            : ((document.documentElement.clientHeight - 80) / 2) * 0.07;
        const mainAnimDistanceY = window.innerWidth < 768 ? -50 : -75;
        // main timeline
        const mainTimeLine = gsap.timeline({
          scrollTrigger: {
            trigger: centeringContainer,
            scrub: true,
            pin: true,
            pinSpacing: true,
            start: "top",
            end: () => `+=${window.innerHeight}`,
            //invalidateOnRefresh: true,
            onUpdate: (self) => {
              if (self.progress > 0.5) {
                if (isFirstAnimActive !== true) {
                  requestAnimationFrame(() => {
                    setIsFirstAnimActive(true);
                    dispatch(updateAnimActive(true));
                    if (annualAnim) {
                      annualAnim.play();
                    }
                    // contentAnim.delay(0.5).play();
                    funPurpleAnim.play();
                    funTanAnim.play();
                    if (window.innerWidth > 1400) {
                      followerLeft.play();
                    }
                  });
                }
              } else {
                requestAnimationFrame(() => {
                  setIsFirstAnimActive(false);
                  dispatch(updateAnimActive(false));
                  if (annualAnim) {
                    annualAnim.reverse();
                  }
                  //contentAnim.timeScale(2).reverse();
                  funPurpleAnim.timeScale(2).reverse();
                  funTanAnim.timeScale(2).reverse();
                  if (window.innerWidth > 1400) {
                    followerLeft.reverse();
                  }
                });
              }
            }
          }
        });

        mainTimeLine
          .to(".animated-header-inner", {
            y: mainAnimDistanceY,
            x: mainAnimDistanceW
          })
          .fromTo(
            wormholeRing,
            {
              rotation: 0.01,
              scale: 0.9
              //force 3d matrix
            },
            {
              rotation: 0.01,
              scale: 1
            },
            "<"
          )
          .fromTo(
            ".animated-content-inner",
            { duration: 0.5, alpha: 0.6 },
            { duration: 0.5, alpha: 1 }
          );

        // set animation variables
        const bg1Anim = gsap.to(background1, { autoAlpha: 0, paused: true });

        //const cellsFadeOut = gsap.to(cells.current, { autoAlpha: 0, paused: true });
        //background1;
        const annualAnim = gsap.to(annualOne, {
          autoAlpha: 0,
          paused: true
        });

        const funPurpleAnim = gsap.fromTo(
          animHeaderPurp,
          { duration: 0.5, alpha: 1 },
          { duration: 0.5, alpha: 0 }
        );

        const funTanAnim = gsap.fromTo(
          animHeaderTan,
          { duration: 0.5, alpha: 0 },
          { duration: 0.5, alpha: 1 }
        );

        const followerLeft = gsap.fromTo(
          followerInner,
          { y: 0, paused: true },
          { y: 200, x: -35, paused: true }
        );

        // Part 2 Timelines
        // animate the wormhole image after all other animations to make it look like the next section slides on top
        const wormholeBackgroundTimeline = gsap.timeline(
          {
            scrollTrigger: {
              trigger: centeringContainer,
              scrub: "1",
              markers: false,
              start: "top top-=150", // start at 100px from the top of the viewport
              end: "bottom top",

              fastScrollEnd: true,
              onUpdate: (self) => {
                if (self.progress > 0.1) {
                  bg1Anim.play();
                } else {
                  bg1Anim.reverse();
                }
              }
            }
          },
          []
        );
        wormholeBackgroundTimeline.to(wormholePage, {
          y: 300,
          rotation: 0.01,
          ease: "power2.inOut"
        });

        //make follower sticky
        gsap.timeline({
          scrollTrigger: {
            trigger: centeringContainer,
            scrub: false,
            start: "100px top",
            //    anticipatePin: 1,
            //    markers: false,
            toggleActions: "play none reverse none",
            end: "bottom bottom",
            onEnter: () => {
              setIsSecondAnimActive(false);
            },
            onLeave: () => {
              setIsSecondAnimActive(true);
            },
            onEnterBack: () => {
              setIsSecondAnimActive(false);
            }
          }
        });

        // }, 0);
      }, centeringContainer); // <- selector scoping
      return () => ctx.revert();
    } else {
      //prefer reduce motion
    }
  }, [centeringContainer, dispatch]);

  return (
    <div id="home-animation" ref={homeAnimation}>
      <section
        id="Hero"
        ref={(el) => (hero = el)}
        className={`hero${isFirstAnimActive ? " anim1-active" : ""} ${
          isSecondAnimActive ? " anim2-active" : ""
        }`}
      >
        <div
          className="centering-container"
          ref={(el) => (centeringContainer = el)}
        >
          <div className="scaling-container" id="ancont">
            <div
              style={{ backgroundImage: `url(${bgImg1})` }}
              className="background-1"
              ref={(el) => (background1 = el)}
            />
            <div className="screen screen-sizing">
              <div className="screen-inner-1 screen-sizing">
                <div className="animation-window">
                  <div className="animation-canvas-1">
                    <div className="hc1-animated hcanim">
                      <div className="hero-circle-1">
                        <img
                          src={circleImg1}
                          alt="250 million invested in research since inception"
                        />
                      </div>
                    </div>
                    <div className="hc2-animated hcanim">
                      <div className="hero-circle-2">
                        <img
                          src={circleImg2}
                          alt="318 Research projects across 81 academic institutions"
                        />
                      </div>
                    </div>
                    <div className="hc3-animated hcanim">
                      <div className="hero-circle-3">
                        <img
                          src={circleImg3}
                          alt="100 percent of all donations fund pancreatic cancer research"
                        />
                      </div>
                    </div>
                    <div className="hc4-animated emptycell">
                      <div className="hero-circle-4" />
                    </div>
                  </div>
                  <div className="ani-track" />
                </div>
              </div>
            </div>
            <div className="wormwrapper">
              <div className="wormpage" ref={(el) => (wormholePage = el)}>
                <div className="wormhole-ring">
                  <div
                    className="wormhole-ring-inner"
                    ref={(el) => (wormholeRing = el)}
                  ></div>
                </div>

                <div
                  className="wormhole"
                  style={isMobile ? mobileStyle : desktopStyle}
                ></div>
              </div>
            </div>
          </div>
          <header className="animated-header animated-parent">
            <div className="animated-header-inner">
              <h1 className="research-img">
                <img
                  style={{ visibility: "hidden" }}
                  src="https://spencerg44.sg-host.com/images/research-is-fun.svg"
                  className="research-is-fun research-ghost"
                  alt=""
                  aria-hidden="true"
                />
                <img
                  src="https://spencerg44.sg-host.com/images/research-is-fun.svg"
                  className="research-is-fun research-is-fun-purple animimg"
                  ref={(el) => (animHeaderPurp = el)}
                  alt="research is fundamental"
                  aria-hidden={isFirstAnimActive}
                />
                <img
                  src="https://spencerg44.sg-host.com/images/research-is-fun-tan.svg"
                  className="research-is-fun research-is-fun-tan animimg"
                  ref={(el) => (animHeaderTan = el)}
                  alt="research is fundamental"
                  aria-hidden={!isFirstAnimActive}
                />
              </h1>
            </div>
          </header>
          <div className="animated-content" ref={(el) => (animContent = el)}>
            <div className="animated-content-inner">
              <p className="leading">
                Lustgarten-funded science has been a driving force in every
                major development in pancreatic cancer research since our
                founding in 1998.
              </p>
              <p className="sub-leading">
                Pancreatic cancer research is advancing faster than ever before,
                driving new breakthroughs in early detection, drug development
                and precision medicine, giving patients the best chance for
                survival and quality of life. The Lustgarten Foundation is
                fueling the research at the center of these transformational
                developments. With the LABS program, strategic research
                partnerships, and bold, creative approach to understanding and
                treating this devastating disease, we are uniquely positioned to
                innovate and focus on high-risk, high-reward science that will
                give patients and their families more time together.
              </p>
            </div>
          </div>
        </div>
        <div className="follower sticky">
          <div className="follower-inner" ref={(el) => (followerInner = el)}>
            <img
              alt="A scientist pours a substance into a petri dish"
              src={researcher}
            />
          </div>
        </div>
      </section>
      <div className="annual-animated" ref={(el) => (annualOne = el)}>
        <div className="annual-inner">
          <img
            src="https://spencerg44.sg-host.com/images/2022.svg"
            alt="2022 annual report"
          />
        </div>
      </div>
    </div>
  );
}

export default Hero;
