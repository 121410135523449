import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { Detection } from "../../pages/detection";
import SubPageHeader from "../../components/subPageHeader";
import QuoteBox from "../../components/quoteBox";
import ButtonRow from "../../components/buttonRow";
import headingBg from "./images/Prevention.png";
import asideBg from "./images/DNA.png";
import asideBg2 from "./images/Syringe.png";
import asideBg3 from "./images/PinkCancer.png";
import asideBg4 from "./images/ElizabethJaffee.png";
export const Prevention = () => {
  let sectionMessage = useRef();

  return (
    <main id="main" className="pushtop">
      <SubPageHeader
        background={headingBg}
        firstLine="Prevention and Early
        Detection Initiatives"
        firstLineColor="purple"
      ></SubPageHeader>
      <section
        id="research"
        className="section subpage-top-sec reg-section bordered-padding"
        ref={sectionMessage}
      >
        <div className="fluid-container">
          <div className="row gx-5">
            <div className="col-md-8 offset-parent">
              <div className="subpage-content">
                <div className="subpage-content-inner">
                  <div className="quote purple">
                    Elizabeth Jaffee, MD, directs the first-ever vaccine trial
                    aimed at preventing pancreatic cancer in healthy individuals
                    with an elevated risk for the disease based on their
                    genetics or family history of pancreatic cancer.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="aside-image"
                style={{ backgroundImage: `url(${asideBg})` }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <section className="section subpage-hr-sec reg-section bordered-padding">
        <div className="fluid-container">
          <div className="row gx-5">
            <div className="col-md-8"></div>
            <div className="col-md-4">
              <hr />
            </div>
          </div>
        </div>
      </section>
      <section className="section subpage-follow-sec reg-section bordered-padding">
        <div className="fluid-container">
          <div className="row gx-5">
            <div className="col-md-8 offset-parent">
              <div className="subpage-content">
                <div className="subpage-content-inner">
                  <div className="para-group">
                    <h2 className="purple">
                      First Pancreatic Cancer Prevention Vaccine Trial Underway
                    </h2>
                    <p>
                      Elizabeth Jaffee, MD, the Lustgarten Foundation’s Chief
                      Medical Advisor, and leader of one of the Lustgarten LABS
                      sites at Johns Hopkins, directs the first-ever vaccine
                      trial aimed at preventing pancreatic cancer in healthy
                      individuals with an elevated risk for the disease based on
                      their genetics or family history of pancreatic cancer. Dr.
                      Jaffee’s Phase 1 study is currently enrolling 25
                      participants and will measure the safety and immune
                      response to a vaccine against mutant KRAS proteins, the
                      most common driver of pancreatic cancer. A mutation in the
                      KRAS gene is one of the first events that transform normal
                      cells into pre-cancer and then cancer. Pre-cancerous
                      lesions in the pancreas take almost 11 years to become
                      cancerous, offering a tremendous window of opportunity for
                      intervention. If deemed safe and effective, the next step
                      is to determine if immunity against this mutated protein
                      is able to delay or prevent pancreatic cancer.
                    </p>
                    <p>
                      This trial builds on Dr. Jaffee’s safety study involving
                      12 patients with early-stage pancreatic cancer who had
                      already undergone surgery. According to findings reported
                      in the October 10, 2022, issue of{" "}
                      <span className="italicize">The New York Times {}</span>
                      (“After Giving Up on Cancer Vaccines, Doctors Start to
                      Find Hope,” by Gina Kolata), none of those patients have
                      had a recurrence two years later. In this study, the
                      vaccine worked by training white blood cells called T
                      cells to recognize cells containing one of the six
                      specific gene mutations driving pancreatic cancer growth
                      in the majority of patients and kill them.
                    </p>
                  </div>
                  <div className="subpage-detail">
                    This cancer prevention vaccine research is funded by the
                    Pancreatic Cancer Collective, an initiative of the
                    Lustgarten Foundation and Stand Up To Cancer to improve
                    pancreatic cancer patient outcomes.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="aside-image"
                style={{ backgroundImage: `url(${asideBg2})` }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <section className="section subpage-hr-sec reg-section bordered-padding">
        <div className="fluid-container">
          <div className="row gx-5">
            <div className="col-md-8"></div>
            <div className="col-md-4">
              <hr />
            </div>
          </div>
        </div>
      </section>
      <section className="section subpage-follow-sec reg-section bordered-padding">
        <div className="fluid-container">
          <div className="row gx-5">
            <div className="col-md-8 offset-parent">
              <div className="subpage-content">
                <div className="subpage-content-inner">
                  <div className="para-group">
                    <h2 className="purple">
                      New Results from the Cancer of the Pancreas Screening-5
                      Study (CAPS-5){" "}
                    </h2>
                    <p>
                      Screening for high-risk patients is critically important
                      to our Lustgarten community. A recent report from the
                      Cancer of the Pancreas Screening-5 Study (CAPS-5)
                      published online in the{" "}
                      <span className="italicize">
                        Journal of Clinical Oncology
                      </span>{" "}
                      found regular screenings with MRIs or endoscopic
                      ultrasounds can lead to early-stage detection and
                      long-term survival. Investigators at Johns Hopkins and
                      seven other medical centers enrolled people who had a
                      genetic variant, raising their risk for pancreatic cancer,
                      or who had more than one first-degree relative (parent,
                      sibling, child) with pancreatic cancer. According to the
                      study, the five-year survival of patients with pancreatic
                      cancer detected by screening is 73%, and the median
                      overall survival is nearly 10 years, compared with 18
                      months for patients diagnosed with pancreatic cancer
                      outside of surveillance.
                    </p>
                  </div>
                  <div className="subpage-detail">
                    The CAPS-5 Study is funded by a Stand Up To
                    Cancer-Lustgarten Foundation Pancreatic Cancer Interception
                    Translational Research Grant, and the Lustgarten Foundation
                    has also funded previous CAPS studies since 1998.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="aside-image"
                style={{ backgroundImage: `url(${asideBg3})` }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <div className="bordered-padding">
        <section className="section  reg-section    med-padding-top">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-12">
                <QuoteBox
                  name="Elizabeth Jaffee, MD"
                  title="Chief Medical Advisor and Leader of the TAG, Lustgarten Foundation"
                  headshot={asideBg4}
                  quote="Collaborations help make more advances. If I look at what the pancreatic cancer expert field looked like even 20 years ago, there were so few of us, and now we have some of the smartest scientists, some of the smartest clinical researchers, very interested in working on pancreatic cancer. We are going to make a difference. I can’t say exactly what is going to happen, but it is going to happen."
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Detection />
      <ButtonRow activeSubpage={1} />
    </main>
  );
};
