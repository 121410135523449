import React from "react";
import "./style.css";
const SubPageHeader = (props) => {
  return (
    <div className="subheader-wrapper">
      <header
        className="page-title basic-subheading"
        style={{
          backgroundImage: `url(${props.background})`,
          backgroundSize: "cover"
        }}
      >
        <div className="fluid-container">
          <div className="page-title-inner">
            <h1 className="med-title">
              <div className={`first-line ${props.firstLineColor}`}>
                {props.firstLine}
              </div>
            </h1>
          </div>
        </div>
      </header>
    </div>
  );
};
SubPageHeader.defaultProps = {
  firstLine: "progress is",
  firstLineColor: "tan-1",
  secondLine: "paramount:",
  secondLineColor: "tan-1",
  punctuation: ".",
  punctuationColor: "tan-1"
};
export default SubPageHeader;
