import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Menu from "./Menu";
import siteLogo from "../images/lustgarten.logo.svg";

import { useSelector } from "react-redux";

const Header = () => {
  let logo = useRef(null);
  let menuBttn = useRef(null);
  let menuBg = useRef(null);
  let menuBgRef = useRef(null);
  let annualHeader = useRef(null);
  // State of our Menu
  let location = useLocation();

  const [state, setState] = useState({
    initial: false,
    clicked: null
  });
  // State of our button
  const [disabled, setDisabled] = useState(false);
  const [menuWhite, setmenuWhite] = useState(false);

  const isFirstAnimActive = useSelector((state) => state.isFirstAnimActive);
  useEffect(() => {
    //Listening for page changes.

    setState({ clicked: false });
  }, [location]);

  //toggle menu
  const [menuOpen, setMenuOpen] = useState(false);

  //toggle menu
  const handleMenu = () => {
    disableMenu();
    setMenuOpen(!menuOpen);
  };

  const removeToggleActive = () => {
    if (menuOpen) {
      handleMenu();
    }
  };
  // check if out button is disabled
  const disableMenu = () => {
    setDisabled(!disabled);
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  };

  const locationClass = location.pathname === "/" ? "home" : "not-home";

  const handleHeader = () => {
    removeToggleActive();
    handleMenu();
  };
  const handleTabPress = (e) => {
    if (e.key === "Tab" && !e.shiftKey) {
      removeToggleActive();
    }
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && menuOpen) {
        handleMenu();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [menuOpen]);

  return (
    <header className={locationClass + " main-menu"}>
      <div className={`header-white${isFirstAnimActive ? " menu-active" : ""}`}>
        <div className="header-background" ref={(el) => (menuBg = el)}></div>
        <div className="wrapper">
          <div className="inner-header">
            <div className="header-left">
              <div className="menu">
                <button
                  id="toggle"
                  className={`toggle-button ${menuOpen ? "toggle-active" : ""}`}
                  ref={(el) => (menuBttn = el)}
                  disabled={disabled}
                  onClick={handleHeader}
                  aria-controls="hamburger-menu"
                  aria-label="Main Menu"
                  aria-haspopup="menu"
                  aria-expanded={menuOpen}
                >
                  <span className="bar top"></span>
                  <span className="bar middle"></span>
                  <span className="bar bottom"></span>
                </button>
              </div>
              <Menu
                state={state}
                removeToggleActive={removeToggleActive}
                menuOpen={menuOpen}
                handleTabPress={handleTabPress} // closes menu on tab press - passing func as prop
              />
              <div className="logo">
                <Link ref={(el) => (logo = el)} to="/">
                  <img alt="Lustgarten Foundation" src={siteLogo} />
                </Link>
              </div>
            </div>
            <div className="header-right">
              <div
                className="annual-animated-header"
                ref={(el) => (annualHeader = el)}
              >
                <div className="annual-inner">
                  <img
                    src="https://spencerg44.sg-host.com/images/2022.svg"
                    alt="2022 annual report"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
