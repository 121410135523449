import React, { useLayoutEffect, useRef } from "react";
import "./style.css";
import CSVList from "../../components/CSVList";

import diamondCircle from "../../csv/diamond-circle.csv";
import goldCircle from "../../csv/gold-circle.csv";
import silverCircle from "../../csv/silver-circle.csv";
import bronzeCircle from "../../csv/bronze-circle.csv";
import matchingGifts from "../../csv/matching-gifts.csv";
import benefactorCircle from "../../csv/benefactor-circle.csv";
import estates500 from "../../csv/Estates-500k.csv";
import estates50 from "../../csv/Estates-50k.csv";
import estates25 from "../../csv/Estates-25k.csv";
import estates10 from "../../csv/Estates-10k.csv";
import estates5 from "../../csv/Estates-5k.csv";
import patronList from "../../csv/Patrons2500-4999-list.csv";
import friendsCircle from "../../csv/Friends1000-2499-list.csv";
import inKind from "../../csv/in-kind.csv";
import legacy from "../../csv/legacy.csv";

import Accordion from "../../components/accordion";

const generateId = () => `${Date.now()}-${Math.random()}`;
const estates25Ast = (
  <div>
    <CSVList file={estates25} />{" "}
    <div className="estate-ast">**final distribution of $16,676,191 gift</div>
  </div>
);
const estates50Ast = (
  <div>
    <CSVList file={estates50} />{" "}
    <div className="estate-ast">*final distribution of $45,979,956 gift</div>
  </div>
);
const items = [
  {
    title: "Diamond Circle",
    numberRange: "$100,000–$999,999",
    subTitle: "In FY2022",
    content: <CSVList file={diamondCircle} />,
    id: generateId()
  },
  {
    title: "Gold Circle",
    content: <CSVList file={goldCircle} />,
    numberRange: "$50,000–$99,999",
    subTitle: "In FY2022",
    id: generateId()
  },
  {
    title: "Silver Circle",
    content: <CSVList file={silverCircle} />,
    numberRange: "$25,000–$49,999",
    subTitle: "In FY2022",
    id: generateId()
  },
  {
    title: "Bronze Circle",
    content: <CSVList file={bronzeCircle} />,
    numberRange: "$10,000–$24,999",
    subTitle: "In FY2022",
    id: generateId()
  },
  {
    title: "Benefactor Circle",
    content: <CSVList file={benefactorCircle} />,
    numberRange: "$5,000–$9,999",
    subTitle: "In FY2022",
    id: generateId()
  },
  {
    title: "Patron Circle",
    content: <CSVList file={patronList} />,
    numberRange: "$2,500–$4,999",
    subTitle: "In FY2022",
    id: generateId()
  },
  {
    title: "Friend Circle",
    content: <CSVList file={friendsCircle} />,
    numberRange: "$1,000-$2,499",
    subTitle: "In FY2022",
    id: generateId()
  },

  {
    title: "Estates - 500k to 1million",
    content: <CSVList file={estates500} />,
    numberRange: "$500,000–$1,000,000",
    subTitle: "In FY2022",
    id: generateId()
  },
  {
    title: "Estates - 50k to 100k",
    content: estates50Ast,
    numberRange: "$50,000–$100,000",
    subTitle: "In FY2022",
    id: generateId()
  },
  {
    title: "Estates - 25k to 50k",
    content: estates25Ast,
    numberRange: "$25,000–$50,000",
    subTitle: "In FY2022",
    id: generateId()
  },
  {
    title: "Estates - 10k to 25k",
    content: <CSVList file={estates10} />,
    numberRange: "$10,000–$25,000",
    subTitle: "In FY2022",
    id: generateId()
  },
  {
    title: "Estates - 5k to 9.9k",
    content: <CSVList file={estates5} />,
    numberRange: "$5,000–$9,900",
    subTitle: "In FY2022",
    id: generateId()
  },
  {
    title: "In-Kind Donors",
    content: <CSVList file={inKind} />,
    numberRange: "",
    subTitle:
      "The Lustgarten Foundation is grateful for the organizations that have generously donated their  products and services to help accelerate progress in pancreatic cancer research.",
    id: generateId()
  },
  {
    title: "Matching Gifts",
    content: <CSVList file={matchingGifts} />,
    numberRange: "",
    subTitle:
      "The Lustgarten Foundation thanks the hundreds of corporate employees across the country who have donated and the employers who have generously matched these donations.",
    id: generateId()
  },
  {
    title: "Legacy of Hope Society",
    content: <CSVList file={legacy} />,
    numberRange: "",
    subTitle:
      "Legacy of Hope donors are making a lasting impact on the lives of future pancreatic cancer patients by including the Foundation in their wills. These gifts ensure the work of the Foundation will endure until a cure for pancreatic cancer can be discovered.",
    id: generateId()
  }
];

export const Donors = () => {
  return (
    <div id="donors" className="donors-wrapper">
      <main id="main" className="pushtop">
        <div className="bg-tan-3">
          <header className="page-title basic-heading stand-alone-heading">
            <div className="container">
              <div className="page-title-inner">
                <h2 className="big-title offset-b-title">
                  <div className="first-line purple">
                    donors<span className="period teal">.</span>
                  </div>
                </h2>
              </div>
            </div>
          </header>
        </div>
        <section
          id="founders"
          className="section basic-section-2 animated-section bg-tan-3"
        >
          <div className="container">
            <div className="row gx-5">
              <div className="col-md-12">
                <div className="stan-width stan-title ">Founders</div>
                <ul className="smart-list">
                  <li>Bill and Ellen Bell</li>
                  <li>Cablevision Systems Corporation</li>
                  <li>Mr. and Mrs. Frederick DeMatteis</li>
                  <li>Mr. and Mrs. Charles F. Dolan</li>
                  <li>Mr. and Mrs. James L. Dolan</li>
                  <li>Mr. and Mrs. Amos B. Hostetter Jr.</li>
                  <li>Mr. and Mrs. John T. Lockton III</li>
                  <li>The Lustgarten Family</li>
                  <li>McMullen Family Foundation</li>
                  <li>Mr. and Mrs. F. F. Randolph Jr.</li>
                  <li>Mr. and Mrs. Leonard Tow</li>
                  <li>Charles B. Wang Foundation</li>{" "}
                </ul>
                <div className="grateful">
                  <div className="inner-content">
                    The Lustgarten Foundation is grateful for our community’s
                    dedication to transforming pancreatic cancer into a curable
                    disease. Because of your generosity, Lustgarten-funded
                    researchers are advancing research from the laboratory to
                    the clinic and giving patients and their families renewed
                    hope.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="Donors"
          className="section basic-padding-bottom animated-section bg-tan-3"
        >
          <div className="container">
            <div className="row gx-5">
              <div className="col-md-12">
                <Accordion className={"donors"} items={items} />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
