import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import SubPageHeader from "../../components/subPageHeader";
import ButtonRow from "../../components/buttonRow";
import headingBg from "./images/CAI.png";
import num1 from "./images/1.svg";
import num3 from "./images/3.svg";
import num6 from "./images/6.1m.svg";

export const CAI = () => {
  let numberOne = useRef();
  let numberTwo = useRef();
  let numberThree = useRef();
  return (
    <main id="main" className="pushtop">
      <SubPageHeader
        background={headingBg}
        firstLine="Clinical Accelerator Initiative"
        firstLineColor="white"
      ></SubPageHeader>

      <div className="bordered-padding">
        <section className="section reg-section med-padding-top">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-lg-4">
                <aside className="aside-text-2">
                  <div className="aside-inner">
                    <div className="aside-intro">
                      <p className="med-p-2">
                        The CAI reduces the time from clinical trial concept to
                        launch by using a Lustgarten-developed process based on
                        the best available science and employing innovative
                        biomarkers. These “smarter” clinical trials generate
                        large volumes of data scientists use to help inform and
                        improve current and future clinical trials and expedite
                        new treatments.
                      </p>
                    </div>
                  </div>
                </aside>
              </div>
              <div className="col-lg-8 offset-parent">
                <div className="subpage-content">
                  <div className="subpage-content-inner">
                    <div className="col-content lg-p">
                      <h2 className="subtle-heading">
                        The Foundation has invested $6.1 million in four CAI
                        studies:
                      </h2>

                      <p className="light-blue italic-p med-p-1">
                        Two studies, one for metastatic patients and one for
                        patients in the neoadjuvant (pre-surgery) setting,
                        examine new combinations of vaccines and drugs targeting
                        the immune system to determine if they can prevent
                        tumors from becoming resistant to immunotherapies.
                      </p>
                      <p className="light-blue italic-p med-p-1">
                        The third study evaluates a new combination of drugs to
                        simultaneously activate the T cells (a type of immune
                        cell) and block the inhibitory signals from the tumor.
                      </p>
                      <p className="light-blue italic-p med-p-1">
                        The fourth CAI-funded study is a Phase II trial
                        assessing a new drug combination to prevent chemotherapy
                        resistance in metastatic pancreatic cancer patients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bordered-padding">
        <section className="section  reg-section ">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-12">
                <aside className="well bg-tan-4">
                  <div className="well-inner">
                    <div className="well-intro">
                      <div className="well-group">
                        <div className="med-title-1">
                          <h2 className="purple">
                            Translational Advisory Group (TAG)
                          </h2>
                        </div>

                        <p className="med-p-2">
                          The TAG drives success of the CAI by identifying,
                          soliciting, vetting, and developing new clinical trial
                          concepts, based on the best available science. CAI
                          studies are typically small, enrolling 10–20 patients,
                          enabling the researchers to deeply analyze each
                          patient and extract the most information possible.
                        </p>
                      </div>
                      <div className="well-group-nopad">
                        <div className="well-title-2">
                          <h2 className="dark-teal">
                            TAG members represent the following institutions:
                          </h2>
                        </div>
                        <ul>
                          <li>Dana-Farber Cancer Institute</li>
                          <li>Duke University</li>
                          <li>Johns Hopkins</li>
                          <li>Massachusetts General Hospital</li>
                          <li>Memorial Sloan Kettering Cancer Center</li>
                          <li>Northwell Health</li>
                          <li>NYU Langone</li>
                          <li>Oregon Health & Science University</li>
                          <li>Princess Margaret Cancer Centre</li>
                          <li>UC San Diego</li>
                          <li>University of Pennsylvania</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bordered-padding">
        <section className="section num-canvas small-section basic-padding-top">
          <div className="container">
            <div className="row">
              <div className="col-md-4" key={1}>
                <div className="number-wrapper">
                  <div ref={numberOne} className="big-number">
                    <img alt="$6.1 million" src={num6} />
                  </div>
                  <div className="number-desc">Invested in 4 CAI Studies</div>
                </div>
              </div>
              <div className="col-md-4" key={2}>
                <div className="number-wrapper">
                  <div ref={numberTwo} className="big-number">
                    <img alt="3" src={num3} />
                  </div>
                  <div className="number-desc">
                    Study Focused on Immunotherapy Resistance
                  </div>
                </div>
              </div>
              <div className="col-md-4" key={3}>
                <div className="number-wrapper">
                  <div ref={numberThree} className="big-number">
                    <img alt="1" src={num1} />
                  </div>
                  <div className="number-desc">
                    Study Focused on Chemotherapy Resistance
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ButtonRow activeSubpage={2} />
    </main>
  );
};
