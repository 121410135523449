import React, { useRef } from "react";
import { Link } from "react-router-dom";
import SubPageHeader from "../../components/subPageHeader";
import QuoteBox from "../../components/quoteBox";
import ButtonRow from "../../components/buttonRow";
import headingBg from "./images/Personalized.png";
import asideBg from "./images/Beaker.png";
import headshot from "./images/DaveTuvesond.png";
import headshot2 from "./images/Jenjen.png";
import innerImg from "./images/doc2-img2.png";
import img1 from "./images/img1.png";
import img2 from "./images/img2.png";
import img4 from "./images/img4.png";
import img5 from "./images/img5.png";
export const Medicine = () => {
  let sectionMessage = useRef();

  return (
    <main id="main" className="pushtop">
      <SubPageHeader
        background={headingBg}
        firstLine="Personalized
        Medicine"
        firstLineColor="white"
      ></SubPageHeader>

      <section
        id="research"
        className="section subpage-wrapper reg-section bordered-padding"
        ref={sectionMessage}
      >
        <div className="fluid-container">
          <div className="row gx-5">
            <div className="col-md-4">
              <div className="subpage-content">
                <div className="subpage-content-inner lg-p ">
                  <div className="content-title-2">
                    <h2 className="purple ">
                      PASS-01 Trial: Predicting Tumor Response
                    </h2>
                  </div>
                  <p>
                    Pancreatic cancer researchers from the United States and
                    Canada are conducting the PASS-01 trial to predict which of
                    the two standard-of-care treatment regimens will work best
                    for individual pancreatic cancer patients based on the
                    molecular traits of their tumors.
                  </p>
                  <p>
                    This landmark study is the first head-to-head comparison of
                    the two standard-of-care chemotherapy regimens for
                    metastatic pancreatic cancer and will include an in-depth
                    analysis of each participant’s tumor and response to
                    therapy. Among the multitude of approaches being implemented
                    in this study, researchers are using organoids to analyze
                    pancreatic tumor biology and drug sensitivity for the first
                    time in a clinical trial of this size.
                  </p>
                  <p>
                    The data generated from organoids in the PASS-01 study will
                    help determine if this technology can be used to predict the
                    most effective treatment for each patient. If successful,
                    this trial can pave the way for organoids to personalize
                    therapies for metastatic pancreatic cancer patients.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="subpage-content">
                <div className="subpage-content-inner">
                  <div className="lead-text purple">
                    “Medical research advances evidence-based medicine. It
                    supports the quality of life of individual patients, like
                    me. It contributes to scientific advances which benefit the
                    lives of others and future generations. We all stand on the
                    shoulders of giants.”
                  </div>
                  <div className="mini-hr">
                    –PASS-01 clinical trial participant at Princess Margaret
                    Cancer Centre, Canada
                  </div>
                  <div className="col-content lg-p para-padding">
                    <div className="content-title-2"></div>

                    <p>
                      The organoid work for the PASS-01 trial is being conducted
                      at the state-of-the-art organoid testing facility at Cold
                      Spring Harbor Laboratory, where researchers are growing
                      and testing organoids for trial participants.
                      Additionally, this organoid research will help determine
                      if neither of the standard-of-care options is likely to
                      help a patient, so a patient’s doctor can pursue a
                      different avenue of treatment.
                    </p>
                    <p>
                      Enrollment is progressing. In 2022, the Lustgarten
                      Foundation opened a new trial site at the British Columbia
                      Cancer Agency in Vancouver, Canada.
                    </p>
                    <div className="subpage-detail">
                      The PASS-01 trial research taking place at Cold Spring
                      Harbor Laboratory is funded in part through the Gail V.
                      Coleman and Kenneth M. Bruntel Organoids for Personalized
                      Therapy Grant.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bordered-padding">
        <section className="section  reg-section    med-padding-bottom">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-12">
                <QuoteBox
                  name="David Tuveson, MD, PhD"
                  title="Chief Scientist, Lustgarten Foundation,
                  Director, LABS site at Cold Spring Harbor Laboratory"
                  headshot={headshot}
                  quote="Lustgarten essentially started the field [of pancreas cancer research] by
                  generating excitement about it and encouraging scientists to work on
                  pancreas cancer. Now, we are using that influence to help close the gap
                  in the rates of early-career women and underrepresented scientists
                  applying for and receiving funding."
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <ButtonRow activeSubpage={5} />
    </main>
  );
};
