import React, { useEffect, useLayoutEffect, useRef } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import SectionHeader from "../../../components/sectionHeader";
import bgImg from "./images/community.jpg";

import circleImg1 from "./images/Community.Couple.png";
import circleImg2 from "./images/Community.Girl.png";
import circleImg3 from "./images/byyou2.svg";

function SectionCommunity() {
  let sectionCommunity = useRef(null);
  let secAnimContainer = useRef(null);

  const [isSecAnimActive, setIsSecAnimActive] = React.useState(false);

  useEffect(() => {
    let heroCircle1 = document.querySelector(".page-circle-1");
    let observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsSecAnimActive(true);
          observer.unobserve(heroCircle1);
        }
      });
    });
    observer.observe(heroCircle1);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div>
      <section
        id="sectionCommunity"
        className="section basic-section animated-section basic-padding bg-tan"
        ref={sectionCommunity}
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div
          ref={secAnimContainer}
          className={`sec-animation-container${
            isSecAnimActive ? " section-anim-active" : ""
          }`}
        >
          <div className="fluid-container">
            <div className="row">
              <div className="col-lg-6">
                <div className="community-part-1">
                  <SectionHeader
                    firstLine="community"
                    secondLine="is power"
                    firstLineColor="purple"
                    secondLineColor="purple"
                    doubleOffset="true"
                    punctuationColor="teal"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="community-part-2">
                  <div className="circle-canvas">
                    <div className="pc1-animated hcanim">
                      <div className="page-circle-1">
                        <img
                          src={circleImg1}
                          alt="A smiling couple Lustgarten logo shirts at a commuity event outdoors."
                        />
                      </div>
                    </div>
                    <div className="pc2-animated hcanim">
                      <div className="page-circle-2">
                        <img
                          src={circleImg2}
                          alt="A girl wearing a hat that ays the word 'Hope' on it."
                        />
                      </div>
                    </div>
                    <div className="pc3-animated hcanim  desktop-only">
                      <div className="page-circle-3">
                        <img
                          src={circleImg3}
                          alt="109 million raised by you since inception"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="link-wrapper">
                  <Link className="sec-link link-style-1" to="/community">
                    Community
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SectionCommunity;
