import React, { useRef } from "react";
import { Link } from "react-router-dom";
import SubPageHeader from "../../components/subPageHeader";
import QuoteBox from "../../components/quoteBox";
import ButtonRow from "../../components/buttonRow";
import headingBg from "./images/Diversity.png";

import headshot from "./images/DaveTuvesond.png";
import headshot2 from "./images/jenjen.png";
import innerImg from "./images/doc2-img2.png";
import live1 from "./images/live2.png";
import img1 from "./images/img2.png";
import img2 from "./images/img3.png";
import img4 from "./images/img4.png";
import img5 from "./images/img5.png";
export const DEI = () => {
  return (
    <main id="main" className="pushtop">
      <SubPageHeader
        background={headingBg}
        firstLine="Diversity, Equity
        & Inclusion"
        firstLineColor="white"
      ></SubPageHeader>

      <div className="bordered-padding">
        <section className="section  reg-section med-padding-top">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-lg-12 offset-parent">
                <div className="subpage-content">
                  <div className="subpage-content-inner">
                    <div className="lg-p">
                      <div className="content-title-2 purple">
                        <h2>
                          Lustgarten’s Commitment to Equity, Diversity, and
                          Accessibility
                        </h2>
                      </div>
                      <p>
                        Solving the complex problem of pancreatic cancer
                        requires a diversity of thought, education, training,
                        background, and experience of the scientists
                        investigating the disease, as well as diversity within
                        patient data and those participating in clinical
                        studies.
                      </p>
                      <div className="content-title-2 purple">
                        <h2>
                          Career Development Awards Honoring Ruth Bader Ginsburg
                          and John Robert Lewis
                        </h2>
                      </div>
                      <p>
                        These awards were created to foster and grow the number
                        of early-career women and underrepresented scientists
                        receiving funding for pancreatic cancer research and to
                        honor the lives and legacies of two iconic Americans
                        lost to pancreatic cancer. Administered by the American
                        Association for Cancer Research (AACR), the awards
                        support trailblazing investigators committed to
                        increasing our understanding and treatment of the
                        disease. Each recipient is awarded a three-year,
                        $300,000 grant for their pancreatic cancer research.
                      </p>
                      <div className="content-title-2 purple">
                        <h2>2022 Recipients:</h2>
                      </div>
                      <div className="content-title-3 black italicize">
                        <h3 className="bold">
                          Career Development Award in Honor of Ruth Bader
                          Ginsburg
                        </h3>
                      </div>
                      <p>
                        Pingping Hou, PhD, an assistant professor at Rutgers
                        University, is investigating an immune cell population
                        called tumor-associated macrophages (TAMS) that has been
                        shown to cause resistance in a therapy targeting KRAS, a
                        key driver of pancreatic cancer.
                      </p>
                      <div className="subpage-detail-2">
                        We gratefully acknowledge Jeannette & Stephen Kordiak
                        for supporting the 2022 Lustgarten AACR Career
                        Development Award for Pancreatic Cancer Research in
                        honor of Ruth Bader Ginsburg.
                      </div>
                      <div className="content-title-3 black italicize med-padding-top">
                        <h3 className="bold">
                          Career Development Award in Honor of John Robert Lewis
                        </h3>
                      </div>
                      <p>
                        Edwin Manuel, PhD, an assistant professor at the Beckman
                        Research Institute of the City of Hope, is developing
                        new methods to combat pancreatic cancer therapeutic
                        resistance and adaptation, key components leading to
                        disease progression.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bordered-padding">
        <section className="section  reg-section basic-padding-top">
          <div className="fluid-container">
            <aside className="well bg-tan-4">
              <div className="row gx-5">
                <div className="col-md-8">
                  <div className="well-inner">
                    <div className="full-img">
                      <img
                        alt="2021 and 2022 award grantees
Drs. Manuel, Engle,Posey and
Hou, and Andrew Rakeman, PhD,
Lustgarten’s VP of Research, attend
the AACR annual meeting."
                        src={img4}
                      />
                    </div>
                  </div>
                  <div className="well-group-nopad"></div>
                </div>

                <div className="col-md-4 align-bottom">
                  <div className="well-inner">
                    <div className="img-caption">
                      <p className="light-gray xl-p">
                        2021 and 2022 award grantees Drs. Manuel, Engle,Posey
                        and Hou, and Andrew Rakeman, PhD, Lustgarten’s VP of
                        Research, attend the AACR annual meeting.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </section>
      </div>
      <div className="bordered-padding">
        <section className="section  reg-section ">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-md-12">
                <hr className="purple-hr   med-padding-top med-padding-bottom" />
                <div className="inner-img">
                  <img
                    alt="Scientist looking at test tube samples"
                    src={innerImg}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bordered-padding">
        <section className="section large-padding-top reg-section">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-md-8 offset-parent">
                <div className="subpage-content">
                  <div className="subpage-content-inner">
                    <div className="xl-p">
                      <div className="content-title-2 purple">
                        <h2>
                          {" "}
                          The Lustgarten Equity, Accessibility, and Diversity
                          (LEAD) Project
                        </h2>
                      </div>
                      <p>
                        The Lustgarten Equity, Accessibility, and Diversity
                        (LEAD) Project was created to increase the recruitment
                        and retention of underrepresented groups in pancreatic
                        cancer clinical trials.{" "}
                      </p>
                      <p>
                        The inaugural LEAD grant was awarded to Jen Jen Yeh, MD,
                        Professor and Vice Chair of Research, Surgery, Division
                        of Oncology Professor, Pharmacology Member, UNC
                        Lineberger Comprehensive Cancer Center, for the
                        PROmoting CLinicAl TrIal EngageMent for Pancreatic
                        Cancer App Study (PROCLAIM Study). As a grant recipient,
                        Dr. Yeh will receive $150,000 per year for three
                        consecutive years to support her research.
                      </p>
                      <p>
                        Dr. Yeh’s PROCLAIM Study, in partnership with Dr. Ugwuji
                        Maduekwe, MD, and her team at the Medical College of
                        Wisconsin, will focus on breaking down barriers to
                        recruiting diverse populations, such as distrust, poor
                        communication, racial bias, and culturally discordant
                        clinical trial materials, via a mobile health technology
                        initiative. Mobile health technology will be used as an
                        educational and communication tool fostering open
                        discussion between Black people with pancreatic cancer
                        and their cancer teams, increasing clinical trial
                        participation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="aside-image"
                  style={{ backgroundImage: `url(${img2})` }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="bordered-padding">
        <section className="section  reg-section    basic-padding-top basic-padding-bottom">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-12">
                <QuoteBox
                  name="Jen Jen Yeh, MD"
                  title="Professor and Vice Chair of Research, Surgery, Division of Oncology Professor,
                  Pharmacology Member, UNC Lineberger Comprehensive Cancer Center"
                  headshot={headshot2}
                  quote="“I am grateful to the Lustgarten Foundation for supporting this study
                  and the important work we will do to break barriers in pancreatic cancer
                  clinical trial participation of underrepresented groups."
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="bordered-padding">
        <section className="section reg-section basic-padding-top basic-padding-bottom">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-md-8">
                <div
                  className="aside-image"
                  style={{ backgroundImage: `url(${img5})` }}
                ></div>
              </div>
              <div className="col-md-4">
                <div className="subpage-content">
                  <div className="subpage-content-inner">
                    <hr className="purple-hr" />
                    <div className="xl-p">
                      <div className="content-title-2">
                        <h2 className="purple ">
                          Probing Racial Disparities in Pancreatic Cancer
                        </h2>
                      </div>
                      <p>
                        Howard Crawford, PhD, Scientific Director, Henry Ford
                        Pancreatic Cancer Center, is leading research analyzing
                        existing tumor samples to determine if genomic and
                        cellular differences may account for the higher rates
                        and poorer outcomes of pancreatic cancer in Black
                        Americans.
                      </p>
                      <div className="subpage-detail-2">
                        This study is funded in part through a grant from
                        Bristol Myers Squibb.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <ButtonRow activeSubpage={6} />
    </main>
  );
};
