import React, { useRef } from "react";
import { Link } from "react-router-dom";
import headingBg from "./images/ProgressBanner.png";
import fpoSlide from "./images/fpo-slide.jpg";
import caiImg from "./images/CAI.png";
import collaborationImg from "./images/Collaboration.jpg";
import detectionImg from "./images/Personalized-sq.png";
import labsImg from "./images/LABS.jpg";
import live1 from "./images/live2.png";
import preventionImg from "./images/Prevention.jpg";
import deiImg from "./images/Diversity.Carousel.png";
import PageHeader from "../../components/pageHeader";
import Carousel from "../../components/carousel";

export const Research = () => {
  let sectionMessage = useRef();

  const slideItems = [
    {
      key: 1,
      content: <h3>Prevention and Early Detection Initiatives</h3>,
      image: preventionImg,
      linkTo: "/research/prevention",
      textColor: "purple"
    },
    {
      key: 2,
      content: (
        <h3>
          Dr. Robert F. Vizza Lustgarten Clinical Accelerator Initiative (CAI){" "}
        </h3>
      ),
      image: caiImg,
      linkTo: "/research/CAI",
      textColor: "white"
    },
    {
      key: 3,
      content: <h3>Led by Lustgarten. Powered by Collaboration</h3>,
      image: collaborationImg,
      linkTo: "/research/collaboration",
      textColor: "white"
    },
    {
      key: 4,
      content: (
        <h3>Lustgarten Advancing Breakthrough Science (LABS) Program</h3>
      ),
      image: labsImg,
      linkTo: "/research/LABS",
      textColor: "white"
    },
    {
      key: 5,
      content: <h3>Personalized Medicine</h3>,
      image: detectionImg,
      linkTo: "/research/personalized-medicine",
      textColor: "white"
    },
    {
      key: 6,
      content: <h3>Diversity, Equity & Inclusion</h3>,
      image: deiImg,
      linkTo: "/research/DEI",
      textColor: "white"
    }
  ];
  return (
    <main id="main" className="pushtop">
      <PageHeader
        background={headingBg}
        firstLine="progress is"
        secondLine="paramount"
        punctuation=":"
      ></PageHeader>

      <section
        id="research"
        className="section offset-top-section reg-section"
        ref={sectionMessage}
      >
        <div className="fluid-container">
          <div className="row gx-5">
            <div className="col-md-7 offset-parent">
              <div className="offset-content-wrapper">
                <div className="offset-content">
                  <div className="offset-content-inner">
                    <p>
                      Lustgarten-funded science has been a driving force in
                      every major development in pancreatic cancer research
                      since our founding in 1998. As the world’s largest private
                      funder of pancreatic cancer research, we aim to advance
                      breakthroughs in the understanding of the initiation and
                      progression of pancreatic cancer to improve the diagnosis
                      and treatment of the disease, providing more hope for the
                      future.
                    </p>
                    <p>
                      Our comprehensive program focuses on early detection, new
                      treatment development, and personalized medicine so we can
                      bring the most cutting-edge research from the lab bench to
                      the clinic, where it can be tested on patients, with the
                      goal of giving patients a better quality of life and the
                      hope for a cure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <aside id="principles" className="basic-aside">
                <h3>Lustgarten Foundation’s Guiding Research Principles:</h3>
                <p>Support innovative research to fill knowledge gaps</p>
                <p>
                  Take large risks to drive new research opportunities and
                  promising initiatives
                </p>
                <p>
                  Create and sustain partnerships with stakeholders and other
                  funders that leverage resources and complement strengths to
                  fill gaps and remove barriers
                </p>
                <p>
                  Cultivate collaboration to bring required expertise to the
                  field and address specific challenges
                </p>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <div className="bordered-padding basic-padding-bottom">
        <section className="section  reg-section offset-top-section basic-padding-bottom">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-12">
                <div className="well  research-well">
                  <div className="well-inner">
                    <div className="well-intro">
                      <div className="well-group xxl-p light-gray">
                        <div className="limit-img med-padding-bottom med-padding-top">
                          <img
                            className="med-padding-bottom"
                            alt="Lustgarten Pacreatic Cancer Research Live!"
                            src={live1}
                          />
                        </div>
                        <p className="well-group">
                          LustgartenLIVE! is a live-streaming series of webinars
                          created to foster awareness and education of our
                          mission with best-in-class experts from leading cancer
                          centers and businesses in research, technology, and
                          science.{" "}
                        </p>
                        <p className="well-group">
                          Our 2022 LustgartenLIVE! events covered the following
                          topics: <br />
                          The Power of Persistence:
                          <br />
                          Women Transforming Cancer Research and Care
                        </p>
                        <p className="well-group">
                          From the Clinic to the Lab, To You:
                          <br />
                          How Research, Led by Lustgarten, is Transforming
                          Patient Care at Your Local Cancer Center
                        </p>
                        <p className="well-group">
                          Leading the Way: Equity, Accessibility, and Diversity
                          in Pancreatic Cancer Research
                        </p>
                        <p className="well-group">
                          The Intersection of Science and Technology:
                          <br />
                          Advancing Patient Care Progress and Promise 2022:
                          <br />
                          The Foundation’s Year-End Report{" "}
                        </p>
                        <div className="subpage-detail-2">
                          Thank you to our Sponsors: Presenting: ViewRay
                          Technologies; Series: Ipsen; Event: Bristol Myers
                          Squibb, Miami Cancer Institute, Northwell Health,
                          Novocure, and Rolfe Pancreatic Cancer Foundation.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="section basic-padding reg-section bg-tan-3">
        <div className="fluid-container">
          <div className="row gx-5">
            <div className="col-md-12">
              <Carousel slides={slideItems} />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
