import React from "react";
import "./style.scss";
const QuoteBox = (props) => {
  return (
    <div className="person-quote-box">
      <div className="person-quote-box-inner">
        <div className="person-quote-content">
          <div className="pqc-inner">
            <div className="person-quote light-purple">
              &ldquo;{props.quote}&rdquo;
            </div>

            <span className="person-name purple">{props.name}</span>
            <span className="person-title">{props.title}</span>
          </div>
        </div>
        <div className="person-img">
          <img src={props.headshot} alt="Elizabeth Jaffee, MD" />
        </div>
      </div>
    </div>
  );
};
QuoteBox.defaultProps = {
  firstLine: "progress is",
  firstLineColor: "tan-1",
  secondLine: "paramount:",
  secondLineColor: "tan-1",
  punctuation: ".",
  punctuationColor: "tan-1"
};
export default QuoteBox;
