import React from "react";
import andrew from "./images/andrew.png";
import andrewSig from "./images/andrew.sig2.png";
import linda from "./images/linda.png";
import lindaSig from "./images/linda.sig2.png";
import kerri from "./images/kerri.png";
import kerriSig from "./images/kerri-sig2.png";
const HopeContent = () => {
  return (
    <div className="hope-content-inner">
      <div className="with-hope-title purple">With Hope and Gratitude,</div>
      <div className="sig-row row gx-5">
        <div className="col-md-4">
          <div className="sig-list-1">
            <div className="headshot-img animation-element sfade delay-1 in-view">
              <img
                alt="Andrew Lustgarten"
                className="big-headshot"
                src={andrew}
              />
            </div>
            <div className="sig-img animation-element sfade delay-1 in-view">
              <img
                alt="Andrew Lustgarten's signature"
                className="signature"
                src={andrewSig}
              />
            </div>
            <div className="sig-name">Andrew Lustgarten</div>
            <div className="sig-det-1">
              Chairman of the Board of Directors, Lustgarten Foundation;
            </div>

            <div className="sig-det-2">Director, MSG Sports Corp.</div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="sig-list-1">
            <div className="headshot-img animation-element sfade delay-1 in-view">
              <img alt="Linda Tantawi" className="big-headshot" src={linda} />
            </div>
            <div className="sig-img animation-element sfade delay-3 in-view">
              <img
                alt="Linda Tantawi's signature"
                className="signature"
                src={lindaSig}
              />
            </div>
            <div className="sig-name">Linda Tantawi</div>
            <div className="sig-det-1">CEO, Lustgarten Foundation</div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="sig-list-1">
            <div className="headshot-img animation-element sfade delay-1 in-view">
              <img alt="Kerri Kaplan" className="big-headshot" src={kerri} />
            </div>
            <div className="sig-img animation-element sfade delay-5 in-view">
              <img
                alt="Kerri Kaplan's signature"
                className="signature"
                src={kerriSig}
              />
            </div>
            <div className="sig-name">Kerri Kaplan</div>
            <div className="sig-det-1">President, Lustgarten Foundation</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HopeContent;
