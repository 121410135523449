import React, { useRef } from "react";
import { Link } from "react-router-dom";
import SectionHeader from "../../../components/sectionHeader";
import bgImg from "./images/financial.jpg";
function SectionFinance() {
  let sectionMessage = useRef();
  let sectionHeader = useRef(null);

  return (
    <section
      id="Finance"
      className="section basic-section animated-section basic-padding"
      ref={sectionMessage}
      style={{ background: "#502e87", backgroundImage: `url(${bgImg})` }}
    >
      <div className="fluid-container">
        <div className="row">
          <div className="col-12">
            <SectionHeader
              firstLine="financial"
              secondLine="performance"
              firstLineColor="tan-1"
              secondLineColor="tan-1"
              punctuationColor="teal"
            />
          </div>
          <div className="col-4"></div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="link-wrapper">
              <Link
                className="sec-link link-style-1 hover-light"
                to="/financials"
              >
                View Data
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionFinance;
