import React, { useState, useEffect } from "react";
import Papa from "papaparse";

const CSVList = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Papa.parse(props.file, {
      header: true,
      download: true,
      complete: (results) => {
        setData(results.data);
      }
    });
  }, [props.file]);

  return (
    <ul className="smart-list">
      {data.map((row, index) => (
        <li key={index}>{row[Object.keys(row)[0]]}</li>
      ))}
    </ul>
  );
};

export default CSVList;
