import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import headingBg from "./images/Finance.Landing.jpg";
import PageHeader from "../../components/pageHeader";
import ChartOne from "./chartOne.js";
import ChartTwo from "./chartTwo.js";
import FinancialTable from "./financialTable.js";
import "./style.scss";

export const Financials = () => {
  const chartContainer = useRef(null);
  const chartContainer2 = useRef(null);
  const [legendInView, setLegendInView] = useState(false);

  useEffect(() => {
    // delays and solves race condition
    setTimeout(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setLegendInView(true);
          }
        });
      });

      observer.observe(chartContainer.current);

      return () => {
        if (chartContainer.current) {
          observer.unobserve(chartContainer.current);
        }
      };
    }, 500);
  }, []);

  return (
    <main id="main" className="pushtop">
      <div className="bg-tan-3">
        <header className="page-title basic-heading stand-alone-heading">
          <div className="narrowed">
            <div className="page-title-inner">
              <h2 className="big-title offset-b-title">
                <div className="first-line purple">
                  performance<span className="period teal">.</span>
                </div>
              </h2>
            </div>
          </div>
        </header>
        <div className="narrowed">
          <section className="section table-canvas small-section med-padding bg-tan">
            <div className="container">
              <div className="row">
                <div className="col12">
                  <FinancialTable />
                </div>
              </div>
            </div>
          </section>
        </div>
        <div id="charts">
          <div className="narrowed">
            <section className="section small-section med-padding bg-tan">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="chart-section">
                      <div className="finance-line-items chart-holder">
                        <div className="chart-1 chart-can">
                          <div id="c1-container">
                            {" "}
                            <ChartOne />
                          </div>
                        </div>

                        <div
                          ref={chartContainer}
                          className={`chart-animation clearfix ${
                            legendInView ? "in-view" : ""
                          }`}
                        >
                          <div className="legend-1 legend">
                            <div className="hc-leg-row hclr-1">
                              <div className="hc-bar"></div>
                              <div className="hclr-inner animation-element slide-left">
                                <div className="hclr-content">
                                  <span className="hc-per">67%</span>{" "}
                                  <span className="hc-name">Contributions</span>{" "}
                                  <span className="hc-num"> $14,508,977</span>
                                </div>
                              </div>
                            </div>
                            <div className="hc-leg-row hclr-2">
                              <div className="hc-bar"></div>
                              <div className="hclr-inner animation-element slide-left">
                                <div className="hclr-content">
                                  <span className="hc-per">25%</span>{" "}
                                  <span className="hc-name">Events</span>{" "}
                                  <span className="hc-num"> $5,343,272</span>
                                </div>
                              </div>
                            </div>

                            <div className="hc-leg-row hclr-3">
                              <div className="hc-bar"></div>
                              <div className="hclr-inner animation-element slide-left">
                                <div className="hclr-content">
                                  <span className="hc-per">7%</span>{" "}
                                  <span className="hc-name">
                                    Investment Income and Royalties
                                  </span>{" "}
                                  <span className="hc-num"> $1,551,299</span>
                                </div>
                              </div>
                            </div>

                            <div className="hc-leg-row hclr-4">
                              <div className="hc-bar"></div>
                              <div className="hclr-inner animation-element slide-left">
                                <div className="hclr-content">
                                  <span className="hc-per">1%</span>{" "}
                                  <span className="hc-name">Gifts-in-kind</span>{" "}
                                  <span className="hc-num"> $180,693</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="charts clearfix">
                      <div className="finance-line-items chart-holder">
                        <div className="chart-1 chart-can">
                          <div id="c2-container">
                            <ChartTwo />
                          </div>
                        </div>
                        <div
                          ref={chartContainer2}
                          className={`chart-animation clearfix ${
                            legendInView ? "in-view" : ""
                          }`}
                        >
                          <div className="legend-1 legend">
                            <div className="hc-leg-row hclr-1">
                              <div className="hc-bar"></div>
                              <div className="hclr-inner animation-element slide-left">
                                <div className="hclr-content">
                                  <span className="hc-per">78%</span>{" "}
                                  <span className="hc-name">Research</span>{" "}
                                  <span className="hc-num"> $26,550,891</span>
                                </div>
                              </div>
                            </div>
                            <div className="hc-leg-row hclr-2">
                              <div className="hc-bar"></div>
                              <div className="hclr-inner animation-element slide-left">
                                <div className="hclr-content">
                                  <span className="hc-per">10%</span>{" "}
                                  <span className="hc-name">Fundraising</span>{" "}
                                  <span className="hc-num"> $3,423,462</span>
                                </div>
                              </div>
                            </div>
                            <div className="hc-leg-row hclr-3">
                              <div className="hc-bar"></div>
                              <div className="hclr-inner animation-element slide-left">
                                <div className="hclr-content">
                                  <span className="hc-per">7%</span>{" "}
                                  <span className="hc-name">
                                    Management &amp; General
                                  </span>{" "}
                                  <span className="hc-num"> $2,392,903</span>
                                </div>
                              </div>
                            </div>
                            <div className="hc-leg-row hclr-4">
                              <div className="hc-bar"></div>
                              <div className="hclr-inner animation-element slide-left">
                                <div className="hclr-content">
                                  <span className="hc-per">3%</span>{" "}
                                  <span className="hc-name">
                                    Professional Education
                                  </span>{" "}
                                  <span className="hc-num"> $817,430</span>
                                </div>
                              </div>
                            </div>
                            <div className="hc-leg-row hclr-5">
                              <div className="hc-bar"></div>
                              <div className="hclr-inner animation-element slide-left">
                                <div className="hclr-content">
                                  <span className="hc-per">2%</span>{" "}
                                  <span className="hc-name">
                                    Public Education
                                  </span>{" "}
                                  <span className="hc-num"> $677,642</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section className="section basic-padding  reg-section  ">
          <div className="container">
            <div className="row gx-5">
              <div className="donate-cta">
                100% of donations fund pancreatic cancer research.
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
