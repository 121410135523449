import React, { useRef } from "react";
import { Link } from "react-router-dom";
import LetterContent from "./letterContent";
import HopeContent from "./hopeContent";

//import headingBg from "./images/heading-bg.jpg";

import "./style.scss";

export const Letter = () => {
  return (
    <main id="main" className="pushtop">
      <section
        id="letter"
        className="section basic-padding  reg-section bg-tan"
      >
        <div className="fluid-container">
          <div className="row gx-5">
            <div className="col-xl-6 ">
              <div className="letter-intro">
                <div className="lettrer-intro-inner">
                  <div className="lead-text purple">
                    <span className="letter-dear med-padding-bottom">
                      Dear Friends:
                    </span>
                    Thanks to you, the Lustgarten Foundation is the backbone and
                    anchor of the pancreatic cancer community. You are our
                    inspiration and the reason we will keep pushing forward.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section basic-padding  reg-section bg-white">
        <div className="fluid-container">
          <div className="row gx-5">
            <div className="letter-content">
              <LetterContent />
            </div>
          </div>
        </div>
      </section>
      <div className="bordered-padding">
        <section className="section basic-padding  reg-section bg-tan-3">
          <div className="container">
            <div className="row gx-5">
              <div className="col-md-12 ">
                <div className="hope-content">
                  <HopeContent />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="section basic-padding  reg-section bg-white">
        <div className="fluid-container">
          <div className="long-button-row">
            <Link className="long-button link-style-2" to="/leadership">
              VIEW OUR LEADERSHIP
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
};
export default Letter;
