import React, { useRef } from "react";
import { Link } from "react-router-dom";
import headingBg from "./images/heading-bg.jpg";
import PageHeader from "../../components/pageHeader";
import LeadershipContent from "./leadershipContent";
import "./style.scss";

export const Leadership = () => {
  return (
    <main id="main" className="pushtop">
      <PageHeader
        background={headingBg}
        firstLine=""
        secondLine="leadership"
        secondLineColor="yellow"
        punctuation="."
        punctuationColor="teal"
        offsetSecondLine={false}
      ></PageHeader>
      <div className="bordered-padding">
        <section
          id="research"
          className="section basic-padding  reg-section bg-tan-3"
        >
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-md-12 ">
                <div className="leader-content">
                  <div className="leader-content-inner">
                    <LeadershipContent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
