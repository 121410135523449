import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import headingBg from "./images/Community.Landing.jpg";
import PageHeader from "../../components/pageHeader";
import CommunityContent from "./communityContent";
import "./style.scss";
import northwell from "./images/NorthWell.png";
export const Community = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const animatedNumberGroup = useRef(null);
  const numberOne = useRef(null);
  const numberTwo = useRef(null);
  const numberThree = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isAnimated) {
          setIsAnimated(true);
          gsap.to(numberOne.current, {
            duration: 3,
            textContent: "+=24",
            roundProps: "textContent",
            ease: "none"
          });
          gsap.to(numberTwo.current, {
            duration: 3,
            textContent: "+=94",
            roundProps: "textContent",
            ease: "none"
          });
          gsap.to(numberThree.current, {
            duration: 3,
            textContent: "+=653",
            roundProps: "textContent",
            ease: "none"
          });
          observer.disconnect();
        }
      });
    });
    observer.observe(animatedNumberGroup.current);
    return () => observer.disconnect();
  }, []);

  return (
    <main id="main" className="pushtop">
      <PageHeader
        background={headingBg}
        firstLine="community"
        firstLineColor="purple"
        secondLine="is power"
        secondLineColor="purple"
        punctuation="."
        punctuationColor="teal"
      ></PageHeader>

      <CommunityContent />
      <div className="bordered-padding">
        <section
          ref={animatedNumberGroup}
          className="section animate-num-canvas small-section med-padding bg-tan"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-4" key={1}>
                <div className="number-wrapper">
                  <div ref={numberOne} className="animated-number">
                    0
                  </div>
                  <div className="number-desc">Walks for Research</div>
                </div>
              </div>
              <div className="col-md-4" key={2}>
                <div className="number-wrapper">
                  <div ref={numberTwo} className="animated-number">
                    0
                  </div>
                  <div className="number-desc">Other Events</div>
                </div>
              </div>
              <div className="col-md-4" key={3}>
                <div className="number-wrapper">
                  <div ref={numberThree} className="animated-number">
                    0
                  </div>
                  <div className="number-desc">Walk Teams</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bordered-padding">
        <section className="section small-section basic-padding bg-tan">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img
                  className="object-img limit-img-sm med-padding-bottom"
                  alt="NorthWell Health Cancer Institute"
                  src={northwell}
                />
              </div>
              <div className="col-md-6">
                <div className="community-detail purple">
                  Northwell Health was the Presenting Sponsor of the 2022 New
                  York metropolitan area walks. Thank you, Northwell Health, for
                  your collaboration and your commitment to supporting
                  pancreatic cancer patients.
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
