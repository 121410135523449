import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import gsap from "gsap";

const Accordion = (props) => {
  const [activeStates, setActiveStates] = useState(
    Array(props.items.length).fill(false)
  );
  const accordionRef = useRef([null]);
  const contentRef = useRef(props.items.map(() => React.createRef()));

  const handleClick = (index) => {
    contentRef.current.forEach((ref, i) => {
      ref.current.setAttribute("data-acc-height", ref.current.scrollHeight);
    });
    contentRef.current.forEach((ref, i) => {
      if (index === i) {
        gsap.to(ref.current, {
          duration: 0.5,
          height: activeStates[i]
            ? 0
            : ref.current.getAttribute("data-acc-height"),
          ease: "power2.inOut"
        });
      }
    });

    setActiveStates(
      activeStates.map((state, i) => (i === index ? !state : state))
    );
  };

  useEffect(() => {
    contentRef.current.forEach((ref, i) => {
      gsap.set(ref.current, { height: 0 });
    });
  }, []);

  return (
    <div ref={accordionRef} className={`sg-accordion ${props.className}`}>
      {props.items.map((item, index) => (
        <div
          key={index}
          className={`accordion-item ${
            activeStates[index] ? "active" : "inactive"
          }`}
        >
          <h2>
            <button
              id={`${props.className}-control-${index}`}
              onClick={() => handleClick(index)}
              aria-controls={`${props.className}-content-${index}`}
              aria-expanded={activeStates[index]}
            >
              <div className="acc-title-wrap">
                <span className="acc-title">{item.title}</span>
                <div className="acc-sub-title-wrap">
                  <span className="bold-nums">{item.numberRange} </span>
                  <span className="sub-title">{item.subTitle}</span>
                </div>
              </div>
              <div className="accordion-icon-area">
                <span className="accordion-icon"></span>
              </div>
            </button>
          </h2>
          <div
            ref={contentRef.current[index]}
            id={`${props.className}-content-${index}`}
            className="content"
            aria-hidden={!activeStates[index]}
            role="region"
            aria-labelledby={`${props.className}-control-${index}`}
          >
            {item.content}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
