import React from "react";

const FinancialTable = () => {
  return (
    <section
      id="finance-details"
      className="section table-canvas small-section   bg-tan"
    >
      <div className="finance-line-items">
        <table>
          <thead>
            <tr>
              <td className="struct" data-label="amount"></td>
              <td>FY2022</td>
              <td>FY2021</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="struct" data-label="amount">
                Total Revenue*
              </td>
              <td data-label="fy2022">$ 21,584,241</td>
              <td data-label="fy2021">$ 23,058,426</td>
            </tr>
            <tr>
              <td className="struct" data-label="amount">
                Total Expenses
              </td>
              <td data-label="fy2022">$ 33,862,328</td>
              <td data-label="fy2021">$ 26,070,546</td>
            </tr>
            <tr>
              <td className="struct" data-label="amount">
                Annual Research Expenses
              </td>
              <td data-label="fy2022">$ 26,550,891</td>
              <td data-label="fy2021">$ 16,556,918</td>
            </tr>
            <tr>
              <td className="struct" data-label="amount">
                Research Commitments Outstanding
              </td>
              <td data-label="fy2022">$ 40,468,633</td>
              <td data-label="fy2021">$ 35,826,663</td>
            </tr>
            <tr>
              <td className="struct" data-label="amount">
                Total Net Assets
              </td>
              <td data-label="fy2022">$ 70,031,633</td>
              <td data-label="fy2021">$ 92,947,157</td>
            </tr>
          </tbody>
        </table>
        <div className="typical-ps">
          <p>
            *Total revenue excludes depreciation in fair value of investments of
            $10,637,437 in FY2022 and an appreciation in fair value of
            investments of $15,218,970 in FY2021.{" "}
          </p>
          <p>
            The financial performance information for FY2022 represents the
            activity of the Foundation for the period from July 1, 2021 through
            June 30, 2022.
          </p>
          <p>
            Financial audit in progress at the time of printing. Upon
            completion, the audited financial statements of the Lustgarten
            Foundation will be made available on our website,
            www.lustgarten.org, or upon request.
          </p>
        </div>
      </div>
    </section>
  );
};
export default FinancialTable;
