import React, { useLayoutEffect, useRef } from "react";
import "./style.css";
import Hero from "./Hero";
import SectionMessage from "./SectionMessage";
import SectionFinance from "./SectionFinance";
import SectionDonors from "./SectionDonors";
import SectionCommunity from "./SectionCommunity";
import SectionMission from "./SectionMission";

export const Home = () => {
  return (
    <div id="home" className="home">
      <main id="main">
        <Hero />
        <SectionMessage />
        <SectionFinance />
        <SectionCommunity />
        <SectionDonors />
        <SectionMission />
      </main>
    </div>
  );
};
