import React, { useRef } from "react";
import { Link } from "react-router-dom";
import SectionHeader from "../../../components/sectionHeader";
import "./style.scss";

import titleImg from "./images/messageTitle.svg";

import andy from "./images/Andrew.png";
import linda from "./images/Linda.png";
import kerri from "./images/Kerri.png";

function SectionMessage() {
  let sectionMessage = useRef();

  return (
    <section
      id="sectionMessage"
      className="section basic-section animated-section basic-padding bg-tan"
      ref={sectionMessage}
    >
      <div className="fluid-container">
        <div className="row gx-5">
          <div className="col-md-6">
            <div className="headshot-group anim-headshots offset-title-comp">
              <div className="headshot">
                <div className="headshot-inner">
                  <img src={andy} className="hs1 fade-in  delay-1" alt="Andy" />
                </div>
              </div>
              <div className="headshot">
                <div className="headshot-inner">
                  <img
                    src={linda}
                    className="hs1 fade-in delay-2"
                    alt="Linda"
                  />
                </div>
              </div>
              <div className="headshot">
                <div className="headshot-inner">
                  <img
                    src={kerri}
                    className="hs1 fade-in delay-3"
                    alt="Kerri"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="section-intro">
              Thanks to you, the Lustgarten Foundation is the backbone and
              anchor of the pancreatic cancer community. You are our inspiration
              and the reason we will keep pushing forward.
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SectionHeader
              firstLine="with hope"
              secondLine="and gratitude"
              firstLineColor="tan-1"
              secondLineColor="tan-1"
              punctuationColor="purple"
              offsetTop="true"
            />
          </div>
          <div className="col-4"></div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="link-wrapper">
              <Link className="sec-link link-style-1" to="/letter">
                Dear Friends
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionMessage;
