import React from "react";
import "./style.css";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedin,
  FaYoutube
} from "react-icons/fa";
import { socialUrls, socialHandles } from "../../contentOptions";

export const Socialicons = (params) => {
  return (
    <div className="footer-social">
      <ul>
        {socialUrls.twitter && (
          <li>
            <a href={socialUrls.twitter}>
              <FaTwitter />
              <span className="social-handle">{socialHandles.twitter}</span>
            </a>
          </li>
        )}
        {socialUrls.instagram && (
          <li>
            <a href={socialUrls.instagram}>
              <FaInstagram />
              <span className="social-handle">{socialHandles.instagram}</span>
            </a>
          </li>
        )}
        {socialUrls.facebook && (
          <li>
            <a href={socialUrls.facebook}>
              <FaFacebookF />
              <span className="social-handle">{socialHandles.facebook}</span>
            </a>
          </li>
        )}
        {socialUrls.linkedin && (
          <li>
            <a href={socialUrls.linkedin}>
              <FaLinkedin />
              <span className="social-handle">{socialHandles.linkedin}</span>
            </a>
          </li>
        )}
        {socialUrls.youtube && (
          <li>
            <a href={socialUrls.youtube}>
              <FaYoutube />
              <span className="social-handle">{socialHandles.youtube}</span>
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};
