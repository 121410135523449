import React, { useRef } from "react";
import { Link } from "react-router-dom";
import SectionHeader from "../../../components/sectionHeader";

function SectionDonors() {
  let sectionMessage = useRef();

  return (
    <section
      id="Donors"
      className="section basic-section animated-section basic-padding bg-tan"
      ref={sectionMessage}
    >
      <div className="fluid-container">
        <div className="row gx-5">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <div className="section-intro">
              Because of your generosity, Lustgarten-funded researchers are
              advancing research from the laboratory to the clinic and giving
              patients and their families renewed hope.
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SectionHeader
              firstLine="grateful for"
              secondLine="our donors"
              firstLineColor="purple"
              secondLineColor="purple"
              punctuationColor="teal"
              doubleOffset="true"
              offsetTop="true"
            />
          </div>
          <div className="col-4"></div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="link-wrapper">
              <Link className="sec-link link-style-1" to="/donors">
                Donor List
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionDonors;
