import React from "react";

const LeadershipContent = () => {
  return (
    <div>
      <div className="leadership-group">
        <div className="mini-heading">
          <h2>Board of Directors </h2>
        </div>

        <ul>
          <li>
            <div className="leader-wrap">
              <span className="name">Andrew Lustgarten</span>
              <span>
                Chairman, Lustgarten Foundation; Director, MSG Sports Corp.
              </span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">James L. Dolan</span>
              <span>
                Vice Chairman, Lustgarten Foundation; Executive Chairman and
                Chief Executive Officer, MSG Entertainment; Executive Chairman,
                MSG Sports Corp.; Interim Executive Chairman, AMC Networks Inc.
              </span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Adam Silver</span>
              <span>
                Vice Chairman, Lustgarten Foundation; Commissioner, National
                Basketball Association
              </span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">Charles F. Dolan</span>
              <span>
                Chairman Emeritus, Lustgarten Foundation; Chairman Emeritus, AMC
                Networks; Former Chairman, Cablevision Systems Corporation
              </span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Robert F. Vizza, PhD</span>
              <span>Chairman Emeritus, Lustgarten Foundation</span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">William Bell</span>
              <span>
                Treasurer, Lustgarten Foundation (term ended 6/30/22); Former
                Vice Chairman, Cablevision Systems Corporation
              </span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">Philip D’Ambrosio</span>
              <span>
                Treasurer, Lustgarten Foundation (effective 7/1/22); Senior Vice
                President and Treasurer, MSG Entertainment Corp.
              </span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">Jennie Fortunoff</span>
              <span>
                Secretary, Lustgarten Foundation; Principal, Esterow Events
                &amp; Consulting, Inc.
              </span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">Sommer Bazuro, PhD</span>
              <span>Chief Medical Officer, IPG Health</span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">John Cobb</span>
              <span>Partner, Paul Hastings</span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Jessica Lustgarten Courtemanche</span>
              <span>
                Vice President/Director, Data and Analysis, DigitasLBi North
                America
              </span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Quentin Dolan</span>
              <span>
                Investment Director, MSG Sports Corp.; Director, MSG Sports
                Corp.
              </span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">Kenneth Goodman, MD, FACR</span>
              <span>
                Former Director, Department of Radiology and Attending
                Physician, St. Francis Hospital
              </span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Marcia Lustgarten</span>
              <span>
                Former Director, Optimum Community, Cablevision Systems
                Corporation
              </span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">Sheila Mahony</span>
              <span>
                Former Former Executive Vice President, Communication,
                Government and Public Affairs, Cablevision Systems Corporation
              </span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Charles R. Schueler</span>
              <span>
                Former Executive Vice President, Media and Community Relations,
                Cablevision Systems Corporation
              </span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Willa Shalit</span>
              <span>
                Co-Founder and Principal, RTM Limited; Co-Founder, Let’s Win!
                Pancreatic Cancer Foundation
              </span>
            </div>
          </li>
          <li className="lastnamefix">
            <div className="leader-wrap ">
              <span className="name">Linda Tantawi</span>
              <span>CEO, Lustgarten Foundation</span>
            </div>
          </li>
        </ul>
      </div>

      <div className="leadership-group">
        <div className="mini-heading">
          <h2>SCIENTIFIC ADVISORY BOARD </h2>
        </div>
        <ul>
          <li>
            <div className="leader-wrap">
              <span className="name">David A. Tuveson, MD, PhD</span>
              <span>
                Chief Scientist, Lustgarten Foundation; Cold Spring Harbor
                Laboratory Cancer Center
              </span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Elizabeth M. Jaffee, MD</span>
              <span>
                Chief Medical Advisor, Lustgarten Foundation; Sidney Kimmel
                Comprehensive Cancer Center at Johns Hopkins
              </span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Peter Allen, MD</span>
              <span>Duke University</span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Dafna Bar-Sagi, PhD</span>
              <span>NYU Langone</span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Luis Diaz, MD</span>
              <span>Memorial Sloan Kettering Cancer Center</span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">Ted Hong, MD</span>
              <span>Massachusetts General Hospital</span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">Tyler Jacks, PhD</span>
              <span>Koch Institute, MIT</span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">Reuben Shaw, PhD</span>
              <span>Salk Institute</span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">Selwyn Vickers, MD</span>
              <span>Memorial Sloan Kettering Cancer Center</span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Bert Vogelstein, MD</span>
              <span>
                The Johns Hopkins Medical School and Sidney Kimmel Comprehensive
                Cancer Center at Johns Hopkins
              </span>
            </div>
          </li>

          <li className="lastnamefix">
            <div className="leader-wrap">
              <span className="name">Jen Jen Yeh, MD</span>
              <span>UNC School of Medicine</span>
            </div>
          </li>
        </ul>
      </div>

      <div className="leadership-group">
        <div className="mini-heading">
          <h2>CORPORATE ADVISORY BOARD, EMERITUS</h2>
        </div>

        <ul>
          <li>
            <div className="leader-wrap">
              <span className="name">The Honorable Jimmy Carter</span>
              <span>39th U.S. President, Honorary Chairman</span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Frank A. Bennack Jr.</span>
              <span>
                Executive Vice Chairman and Former Chief Executive Officer,
                Hearst Corporation
              </span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Barry Diller</span>
              <span>
                Chairman &amp; Senior Executive, IAC/InterActiveCorp and
                Expedia, Inc.
              </span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">John S. Hendricks</span>
              <span>
                Founder &amp; Former Chairman, Discovery Communications
              </span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">Mel Karmazin</span>
              <span>
                Former Chief Executive Officer, Sirius XM Radio, Inc.;
                Co-Founder, Infinity Broadcasting
              </span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">Gerald M. Levin</span>
              <span>
                Former Chairman and Chief Executive Officer, Time Warner, Inc.
              </span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">John C. Malone</span>
              <span>
                Former President and Chief Executive Officer,
                Tele-Communications, Inc.; Chairman, Liberty Media Corporation
              </span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">Rupert Murdoch</span>
              <span>
                Executive Chairman, News Corp; Chairman, Fox Corporation
              </span>
            </div>
          </li>

          <li>
            <div className="leader-wrap">
              <span className="name">Tom Rogers</span>
              <span>Executive Chairman, WinView; Founder, CNBC and MSNBC</span>
            </div>
          </li>
          <li>
            <div className="leader-wrap">
              <span className="name">Sir Howard Stringer</span>
              <span>
                Non-Executive Director, British Broadcasting Corporation; Former
                Chairman and CEO, Sony Corporation
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default LeadershipContent;
