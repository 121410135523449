import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import SubPageHeader from "../../components/subPageHeader";
import QuoteBox from "../../components/quoteBox";
import ButtonRow from "../../components/buttonRow";
import headingBg from "./images/LABS.png";
import asideBg from "./images/PurpleCancer.png";
import headshot from "./images/dr-reuben-shaw.png";
import innerImg from "./images/FemaleResearcher.png";
export const LABS = () => {
  let sectionMessage = useRef();

  return (
    <main id="main" className="pushtop">
      <SubPageHeader
        background={headingBg}
        firstLine="Lustgarten Advancing Breakthrough Science (LABS) Program"
        firstLineColor="white"
      ></SubPageHeader>

      <section
        id="research"
        className="section subpage-wrapper reg-section bordered-padding"
        ref={sectionMessage}
      >
        <div className="fluid-container">
          <div className="row gx-5">
            <div className="col-md-8 offset-parent">
              <div className="subpage-content">
                <div className="subpage-content-inner">
                  <div className="lead-text purple">
                    The Lustgarten Foundation’s unrelenting, 25-year commitment
                    to funding basic science has led to an improved
                    understanding of the biology underlying pancreatic cancer,
                    resulting in the identification of new dependencies that can
                    be pursued therapeutically.
                  </div>
                  <div className="col-content lg-p para-padding">
                    <p>
                      As a force for progress in the scientific community, the
                      Lustgarten Foundation’s research initiatives are focused
                      on projects to identify novel drug targets and develop and
                      advance preclinical and clinical testing of new drugs and
                      drug combinations in pancreatic cancer.
                    </p>
                    <p>
                      A hallmark of the Lustgarten Foundation’s unique research
                      strategy, the LABS Program provides long-term funding to
                      promote the development of innovative ideas and speed the
                      pace of discovery by incentivizing interdisciplinary team
                      science.
                    </p>
                    <p>
                      Guided by our patient-centered mission and science-driven
                      strategy, Lustgarten supports flexibility, fearlessness,
                      creative confidence, and a culture of innovation and
                      dedication to scientific rigor. The LABS Program is
                      committed to sharing expertise across diverse fields with
                      the goal of advancing knowledge and refining our
                      collective understanding of complex systems and pathways.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="aside-image"
                style={{ backgroundImage: `url(${asideBg})` }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <div className="bordered-padding">
        <section className="section  reg-section    med-padding-top">
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-12">
                <QuoteBox
                  name="Reuben Shaw, PhD"
                  title="Director of the LABS site, Salk Institute"
                  headshot={headshot}
                  quote="We are honored to be the first LABS site on the West Coast. For the first time,
                  we’ve created a collaborative space on campus—the Lustgarten Center—for each
                  of our four participating labs to share equipment and resources. This allows
                  researchers to bring their individual areas of complementary expertise to bear on
                  the shared goal of curing pancreatic cancer."
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bordered-padding">
        <section
          className="section  reg-section med-padding-top"
          ref={sectionMessage}
        >
          <div className="fluid-container">
            <div className="row gx-5">
              <div className="col-lg-4">
                <aside className="aside-text bg-tan-3">
                  <div className="aside-inner">
                    <div className="aside-intro">
                      With the Lustgarten Foundation’s broad vision and dynamic
                      strategy, each of the institutions participating in the
                      LABS Program brings unique ideas and strengths to solve
                      the most challenging problems of pancreatic cancer.
                    </div>
                    <div className="aside-group-wrapper">
                      <div className="aside-info-group">
                        <div className="aside-title">
                          Cold Spring Harbor Laboratory
                        </div>
                        <div className="aside-subtitle">
                          Leader: David Tuveson, MD, PhD
                        </div>
                      </div>
                      <div className="aside-info-group">
                        <div className="aside-title">
                          Dana-Farber Cancer Institute
                        </div>
                        <div className="aside-subtitle">
                          Leader: Brian Wolpin, MD, MPH
                        </div>
                      </div>
                      <div className="aside-info-group">
                        <div className="aside-title">
                          Johns Hopkins (2 LABS sites)
                        </div>
                        <div className="aside-subtitle">
                          Leaders: Bert Vogelstein, MD and Elizabeth Jaffee, MD
                        </div>
                      </div>
                      <div className="aside-info-group">
                        <div className="aside-title">
                          Massachusetts Institute of Technology
                        </div>
                        <div className="aside-subtitle">
                          Leader: Tyler Jacks, PhD
                        </div>
                      </div>
                      <div className="aside-info-group">
                        <div className="aside-title">Salk Institute</div>
                        <div className="aside-subtitle">
                          Leader: Reuben Shaw, PhD
                        </div>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
              <div className="col-lg-8 offset-parent">
                <div className="subpage-content">
                  <div className="subpage-content-inner mobile-padding-top">
                    <div className="col-content lg-p ">
                      <p>
                        In 2022, the Lustgarten Foundation launched a new
                        strategic partnership with the prestigious Salk
                        Institute in La Jolla, CA—the first LABS site on the
                        West Coast. The researchers participating in this
                        five-year, $5 million collaboration are working to
                        identify and validate new targets for pancreatic cancer
                        drugs and biomarkers, with the goal of advancing the
                        most promising discoveries to the next stage of drug and
                        biomarker discovery and development. Led by Reuben Shaw,
                        PhD, Director of Salk’s National Cancer
                        Institutedesignated Cancer Center, and co-principal
                        investigators Ronald Evans, PhD, Tony Hunter, PhD and
                        Dannielle Engle, PhD, the Salk Dedicated Program in
                        Pancreatic Cancer expands on the Salk Institute’s core
                        strength of attacking cancer from multiple angles.
                      </p>
                    </div>
                    <div className="inner-img med-padding-top">
                      <img
                        alt="Scientist looking at test tube samples"
                        src={innerImg}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ButtonRow activeSubpage={4} />
    </main>
  );
};
