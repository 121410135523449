import React, { useRef, useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// accessibility module
require("highcharts/modules/accessibility")(Highcharts);
const options = {
  chart: {
    type: "pie"
  },
  legend: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  title: {
    text: "Total Expenses",

    style: {
      color: "#4D008C",
      fontSize: "24px",
      fontFamily: "Playfair Display"
    }
  },
  subtitle: {
    text: "<span>$33,862,328</span>",

    style: {
      color: "#4D008C",
      fontSize: "24px",
      fontFamily: "Lato"
    }
  },
  plotOptions: {
    pie: {
      shadow: false
    }
  },
  yAxis: {
    labels: {
      formatter: function () {
        return Highcharts.numberFormat(this.value, 0, "", ",");
      }
    }
  },
  tooltip: {
    formatter: function () {
      return "<b>" + this.point.name + "</b>: " + this.y + " %";
    }
  },
  series: [
    {
      name: "Amount",
      data: [
        ["Research", 78],
        ["Fundraising", 10],
        ["Management & General", 7],
        ["Professional Education", 3],
        ["Public Education", 2]
      ],
      colors: ["#4D008C", "#0084AE", "#C1A02E", "#404040", "#47a586"],
      size: "100%",

      showInLegend: true,
      dataLabels: {
        enabled: false
      }
    }
  ]
};
const ChartOne = () => {
  const [isInView, setIsInView] = useState(false);
  const chartRef = React.createRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      });
    });

    observer.observe(chartRef.current);

    return () => observer.disconnect();
  }, [chartRef]);

  return (
    <div ref={chartRef}>
      {isInView && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
};

export default ChartOne;
