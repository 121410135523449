import React, { useState, useEffect, useRef, createRef } from "react";
import Slider from "react-slick";
import { useNavigate, Link } from "react-router-dom";
import "./style.scss";
import "slick-carousel/slick/slick.css";
import gsap, { TweenMax } from "gsap";
//import "slick-carousel/slick/slick-theme.css";

const Carousel = (props) => {
  let smallBall = useRef(null);
  let bigBall = useRef(null);

  const imageRefs = useRef([]);

  // Initialize the image refs array
  useEffect(() => {
    imageRefs.current = new Array(props.slides.length)
      .fill()
      .map((_, i) => imageRefs.current[i] || createRef());
  }, [props.slides.length]);

  // Handle mouseover event
  const handleMouseOver = (index) => {
    //gsap.to(imageRefs.current[index], { scale: 1.1 });
  };

  // Handle mouseleave event
  const handleMouseLeave = (index) => {
    //gsap.to(imageRefs.current[index], { scale: 1, duration: 0.5 });
  };

  // Hover an element
  const onMouseHover = () => {
    gsap.to(bigBall, 0.3, {
      alpha: 1,
      scale: 3
    });
  };
  const onMouseHoverOut = () => {
    gsap.to(bigBall, 0.3, {
      scale: 1,
      alpha: 0
    });
  };

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const navigate = useNavigate();

  const handleDragStart = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };
  const handleMouseMove = (event) => {
    console.log("draggingStart-MM");
    if (isMouseDown) {
      setIsDragging(true);
      console.log(isDragging);
    }

    gsap.to(bigBall, {
      x: event.clientX - 15,
      y: event.clientY - 15
    });
  };
  const handleMouseDown = () => {
    setIsMouseDown(true);
  };
  const handleMouseUp = (event, slide) => {
    event.preventDefault();
    if (isDragging) {
      event.preventDefault();
    }
    if (!isDragging) {
      navigate(slide.linkTo);
    }
    //setInterval(function () {
    setIsDragging(false);
    setIsMouseDown(false);
    //  }, 200);
  };
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick} />;
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick} />;
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 650,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      }
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: setCurrentSlide
  };

  return (
    <div className="special-carousel">
      <div className="cursor">
        <div className="cursor__ball cursor__ball--big ">
          <svg height="30" width="30" ref={(el) => (bigBall = el)}>
            <circle
              fill="purple"
              cx="15"
              cy="15"
              r="12"
              strokeWidth="0"
            ></circle>
          </svg>
        </div>
      </div>
      <div
        className="slick-wrapper"
        onMouseMove={(event) => handleMouseMove(event)}
      >
        <Slider {...settings}>
          {props.slides.map((slide, index) => (
            <div
              key={index}
              className="sg-slide"
              onMouseOver={() => handleMouseOver(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              <Link
                className="slide-link"
                to={slide.linkTo}
                onClick={(event) => event.preventDefault()}
                onDragStart={handleDragStart}
                //onDragEnd={handleDragEnd}
                onMouseDown={(event) => handleMouseDown()}
                onMouseUp={(event) => handleMouseUp(event, slide)}
                onMouseOver={() => onMouseHover()}
                onMouseLeave={() => onMouseHoverOut()}
              >
                <img
                  src={slide.image}
                  alt=""
                  ref={(el) => (imageRefs.current[index] = el)}
                />
                <div className="slide-content">
                  <div className={`slide-content-inner ${slide.textColor}`}>
                    {slide.content}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
